<template>
  <div id="app">
    <AppLoader v-if="!isSettingsLoaded" />
    <component :is="'main-layout'" v-if="isSettingsLoaded">
      <transition :name="$route.meta.transition" appear>
        <router-view :key="$route.fullPath" />
      </transition>
    </component>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import AppLoader from "@/components/app/AppLoader.vue";
import MainLayout from "./layouts/MainLayout.vue";
//import LoginLayout from "./layouts/LoginLayout.vue";

export default {
  name: "App",
  components: {
    AppLoader,
    MainLayout,
    //    LoginLayout,
  },
  computed: {
    ...mapGetters(["isSettingsLoaded"]),
    layout() {
      return this.$route.meta.layout;
    },
  },
  methods: {
    ...mapActions(["fetchSettings"]),
  },
  async mounted() {
    if (!this.isSettingsLoaded) {
      await this.fetchSettings(this.$cookies.get("user_language"));
    }
  },
  beforeDestroy() {
    let rememberMe = localStorage.getItem("rememberMe");
    if (!rememberMe) {
      localStorage.removetItem("user_token");
    }
  },
};
</script>

<style>
@import /* webpackPreload: true */ "assets/site.css";
</style>
