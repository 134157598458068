<template>
  <div class="breadcrumbs">
    <ul>
      <li>
        <router-link to="/">{{ translations("Home") }}</router-link>
      </li>
      <li v-for="(breadcrumb, idx) in breadcrumbList" :key="idx">
        <router-link :to="linkProp(breadcrumb)">{{
          translations(breadcrumb.meta.breadcrumb)
        }}</router-link>
      </li>
      <li v-if="addItem.name">
        <router-link :to="addItem.path">{{ addItem.name }}</router-link>
      </li>
      <li>
        <span v-if="curItem.name">{{
          $route.meta.breadcrumb
            ? translations($route.meta.breadcrumb)
            : translations(curItem.name)
        }}</span>
        <span v-else>{{
          translations($route.meta.breadcrumb || $route.name)
        }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Breadcrumbs",
  data: () => {
    return {
      breadcrumbList: [],
    };
  },
  props: {
    // подставляется вместо текущего названия страницы, если нужно его заменить
    curItem: {
      type: Object,
      default: () => ({}),
    },
    // добавлется, как доп ссылка, перед текущим названием
    addItem: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    // Return the correct prop data
    linkProp: function (crumb) {
      // If it's a named route, we'll base the route
      // off of that instead
      if (crumb.name) {
        return {
          name: crumb.name,
          params: this.$route.params,
        };
      }

      return {
        path: crumb.path,
        params: this.$route.params,
      };
    },
    scanRoute() {
      this.breadcrumbList = [];
      this.$route.matched.forEach((route) => {
        //console.log(this.$route, route.path);
        var hasBreadcrumb = route.meta && route.meta.breadcrumb;

        if (hasBreadcrumb && this.$route.path !== route.path) {
          this.breadcrumbList.push(route);
        }
      });
    },
  },
  watch: {
    $route() {
      this.scanRoute();
    },
  },
  mounted() {
    this.scanRoute();
    //console.log(this.$route.matched);
  },
};
</script>