import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchFirstDocs(context, {countryId, lang}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('country_id', countryId)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/first-docs', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.docs) {
          console.log(body.docs)
          context.commit('updateDocs', body.docs)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    setCurFirstDoc(context, curDoc) {
      context.commit('updateCurDoc', curDoc)
    },
    setCurFirstDocId(context, curDocId) {
      context.commit('updateCurDocId', curDocId)
    },
		refreshFirstDocs(context) {
			context.commit('docsLoaded', false)
		}
  },
  mutations: {
    updateDocs(state, docs) {
      state.docs = docs
      state.docsLoaded = true
      state.curDoc = 0
    },
    updateCurDoc(state, curDoc) {
      state.curDoc = curDoc
    }, 
    updateCurDocId(state, curDocId) {
      state.curDoc = state.docs.find((item) => item.id === +curDocId)
    }, 
		docsLoaded(state, loaded) {
			if (!loaded) {
				state.docs = []
				state.curDoc = 0
			}
			state.docsLoaded = loaded
		},
  },
  state: {
    docsLoaded: false,
    docs: [],
    curDoc: 0,
  },
  getters: {
    firstDocsLoaded(state) {
      return state.docsLoaded
    },
    curFirstDoc(state) {
      return state.curDoc
    },
    listFirstDocs(state) {
      return state.docs
    },
  }
}