<template>
  <div
    :class="[
      'form-field',
      { hasValue: textValue },
      { invalid: v && v.$error },
      { valid: !v.$error && v.$dirty },
    ]"
  >
    <input
      :type="$props.inputType"
      autocomplete="off"
      v-model.trim="textValue"
    />
    <label
      >{{ fieldLabel }}
      <span v-if="v && v.$params['required']" class="required">*</span>
    </label>
    <small :class="{ opened: errorMessage }" class="error-label">{{
      errorMessage
    }}</small>
  </div>
</template>

<script>
import { getValidationErrorMessage } from "@/config";
import { mapGetters } from "vuex";

//const touchMap = new WeakMap();

export default {
  name: "TextField",
  props: {
    fieldLabel: {
      type: String,
      required: true,
    },
    inputType: {
      type: String,
      default: "text",
    },
    value: {
      type: String,
      default: "",
    },
    onlyAlhpa: {
      type: Boolean,
      default: false,
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["translations"]),
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        /*
        this.v.$reset();
        if (touchMap.has(this.v)) {
          clearTimeout(touchMap.get(this.v));
        }
        touchMap.set(
          this.v,
          setTimeout(() => {
            this.v.$touch();
          }, validationDelay)
        );
        */
        this.$emit("input", value);
      },
    },
    errorMessage() {
      if (this.v.$error) {
        let msg = getValidationErrorMessage(this.v);
        return this.translations(msg.message, msg.params || []);
      }
      return "";
    },
  },
};
</script>
