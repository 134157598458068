import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchReviews(context, lang) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/reviews', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.reviews) {
          console.log(body.reviews)
          context.commit('updateReviews', body.reviews)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
  },
  mutations: {
    updateReviews(state, reviews) {
      state.reviews = reviews
      state.reviewsLoaded = true
    },
  },
  state: {
    reviewsLoaded: false,
    reviews: [],
  },
  getters: {
    reviewsLoaded(state) {
      return state.reviewsLoaded
    },
    listReviews(state) {
      return state.reviews
    },
  }
}