import { VUE_APP_BACK_URL, countryExpire } from '@/config';
import expiringStorage from "@/expiringStorage";
import Vue from 'vue'
import 'whatwg-fetch'

export default {
  actions: {
    async fetchCountries(context, {country = 0, countryCode = ''}) {
      console.log('country: ', country)
      let bodydata = new FormData();
      bodydata.append('lang', Vue.prototype.$cookies.get("user_language"))
      bodydata.append('country', country ? country : 0)
      bodydata.append('country_code', countryCode ? countryCode : '')
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      context.commit('startCountriesLoading')
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/countries', requestOptions) 
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.countries) {
          console.log('countries: ', body.countries)
          context.commit('updateCountries', body.countries)
          context.commit('updatePopularCountries', body.popular)
          if (country || countryCode) {
            console.log('used curCountry: ', body.curCountry)
            context.commit('updateCurCountryId', body.curCountry)
          } else {
            let previousSelectedCountry = expiringStorage.get('country-selected');
            if (!previousSelectedCountry) {
              expiringStorage.set(
                'country-selected',
                body.popular[0].id,
                countryExpire
              );
              previousSelectedCountry = body.popular[0].id
            }
            context.commit('updateCurCountryId', previousSelectedCountry)
          }
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    setCurCountryId(context, cur_country_id) {
      expiringStorage.set(
        'country-selected',
        cur_country_id,
        countryExpire
      );
      context.commit('updateCurCountryId', cur_country_id)
    },
    setCurPageCountry(context, link) {
      context.commit('updatePageCountry', link)
    },
    setCurCountryByCode(context, code) {
      context.commit('updateCurCountryByCode', code)
    },
		refreshCountries(context) {
			context.commit('countriesLoaded', false)
		}
  },
  mutations: {
    updateCountries(state, countries) {
      state.countries = countries
      state.countriesLoaded = true
    },
    startCountriesLoading(state) {
      state.countriesLoading = true
    },
    updatePopularCountries(state, popular) {
      state.popularCountries = popular
      state.countriesLoading = false
    },
    updateCurCountryId(state, cur_country_id) {
      state.cur_country_id = cur_country_id
      state.cur_country = state.countries.find((country) => country.id === +cur_country_id)
    },
    updatePageCountry(state, link) {
      const found = state.countries.find((country) =>
        country.link_sef === link.replace('/country/', '').replace('/tariffs/', ''))
      if (found) {
        state.cur_country_id = found.id
        state.cur_country = found
      } else {
        state.cur_country_id = 0
        state.cur_country = []
      }
    },
		countriesLoaded(state, loaded) {
			if (!loaded) {
				state.countries = []
				state.popularCountries = []
				state.cur_country_id = 0
			}
			state.countriesLoaded = loaded
		},
  },
  state: {
    countriesLoading: false,
    countriesLoaded: false,
    countries: [],
    popularCountries: [],
    cur_country_id: 0,
    cur_country: [],
  },
  getters: {
    isCountriesLoaded(state) {
      return state.countriesLoaded
    },
    isCountriesLoading(state) {
      return state.countriesLoading
    },
    allCountries(state) {
      return state.countries
    },
    allCountriesSelect(state) {
      return state.countries.map((country) => {
        return { value: country.id, text: country.text }
      })
    },
    defCountrySelect(state) {
      const defCountry = state.countries.find((country) => country.code === "RU")
      return { value: defCountry.id, text: defCountry.text }
    },
		allFilteredCountries: (state) => (phrase) => {
      return state.countries.filter(
        (country) => country.text.toUpperCase().indexOf(phrase.toUpperCase()) === 0 )
		},
    popularCountries(state) {
      return state.popularCountries
    },
		popularFilteredCountries: (state) => (phrase) => {
      return state.popularCountries.filter(
        (country) => country.text.toUpperCase().indexOf(phrase.toUpperCase()) === 0 )
		},
    onlineCountries(state) {
      return state.countries.filter((country) => +country.online === 1)
    },
    curCountryId(state) {
      return state.cur_country_id
    },
    curCountry(state) {
      return state.cur_country
    },
},
}