import { VUE_APP_BACK_URL } from '@/config';
import Vue from 'vue'
import 'whatwg-fetch'

export default {
  actions: {
    async userDocuments(context, {lang, type, pagesize, page}) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('lang', lang)
      userdata.append('doc_type', type)
      userdata.append('pagesize', pagesize)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      
      context.commit('refrechDocuments')

      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-documents?page=' + page, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.documents) {
          console.log(body.documents)
          context.commit('updateDocumentsCount', body.documents.count);
          context.commit('updateDocuments', body.documents.list);
          context.commit('updateDocumentsPage', body.documents.page);
        }
      } else {
        if (response.status === 401) {
          throw new Error("IncorrectToken");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingDocuments");
        }
      }
    },
    async userGetAct(context, {lang, id}) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-act?id=' + id, requestOptions)
      console.log('response: ', response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `act.pdf`;

        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
          link.remove();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        if (response.status === 401) {
          throw new Error("IncorrectToken");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingAct");
        }
      }
    },
    async userGetInvoice(context, {lang, id}) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-invoice?id=' + id, requestOptions)
      console.log('response: ', response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'invoice.pdf';

        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
          link.remove();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        if (response.status === 401) {
          throw new Error("IncorrectToken");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingAct");
        }
      }
    },
    async userYurlitsa(context, {pagesize, page}) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('pagesize', pagesize)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };

      context.commit('refreshYurlitsa')

      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-yurlitsa?page='+page, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.yourlitsa) {
          console.log(body.yourlitsa)
          context.commit('updateYurlitsaCount', body.yourlitsa.count);
          context.commit('updateYurlitsa', body.yourlitsa.list);
          context.commit('updateYurlitsaPage', body.yourlitsa.page);
        }
      } else {
        if (response.status === 401) {
          throw new Error("Incorrect Token");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingYurlitsa");
        }
      }
    },
    async userProducts(context, {lang, pagesize, page}) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('lang', lang)
      userdata.append('pagesize', pagesize)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };

      context.commit('refreshGotProducts')

      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-products?page='+page, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.orders) {
          console.log('orders: ', body.orders)
          context.commit('updateGotProductsCount', body.orders.count);
          context.commit('updateGotProducts', body.orders.list);
          context.commit('updateGotProductsPage', body.orders.page);
        }
      } else {
        if (response.status === 401) {
          throw new Error("Incorrect Token");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingReceived");
        }
      }
    },
    async userYurlitsoUse(context, id) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-yurlitso-use?id='+id, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log(body)
        console.log('context', context)
        context.commit('updateYurlitsoDef', {
          id,
          user: context.rootState.settings.user
        });
      } else {
        if (response.status === 401) {
          throw new Error("Incorrect Token");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingYurlitsa");
        }
      }
    },
    async userYurlitsoEdit(context, data) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('name', data.name)
      userdata.append('inn', data.inn)
      userdata.append('ogrn', data.ogrn)
      userdata.append('yur_address', data.yur_address)
      userdata.append('rasch_sch', data.rasch_sch)
      userdata.append('kpp', data.kpp)
      userdata.append('bik', data.bik)
      userdata.append('bank', data.bank)
      userdata.append('token', data.token)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };

      let response = null
      if (data.id) {
        response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-yurlitso-update?id='+data.id, requestOptions)
      } else {
        response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-yurlitso-add', requestOptions)
      }
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log(body)
        if (data.id) {
          context.commit('updateYurlitso', data);
        } else {
          data.id = body.id
          context.commit('addYurlitso', data);
        }
      } else {
        if (response.status === 401) {
          throw new Error("Incorrect Token");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorUpdatingYurlitso");
        }
      }
    },
    async userYurlitsoDelete(context, id) {
      let userdata = new FormData();
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      const requestOptions = {
        method: 'POST',
        body: userdata
      };

      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/user-yurlitso-delete?id='+id, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log(body)
        context.commit('deleteYurlitso', id);
      } else {
        if (response.status === 401) {
          throw new Error("Incorrect Token");
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorUpdatingYurlitso");
        }
      }
    },
    clearUser(context) {
      context.commit('clearUserInfo');
    },
  },
  mutations: {
    clearUserInfo(state) {
      state.documentsLoaded = false
      state.documentsCount = 0
      state.documents = []
      state.documentsPage = 1
      state.yurlitsaLoaded = false
      state.yurlitsaCount = 0
      state.yurlitsa = []
      state.yurlitsaPage = 1
      state.gotProductsLoaded = false
      state.gotProductsCount = 0
      state.gotProducts = []
      state.gotProductsPage = 1
    },
    refreshGotProducts(state) {
      state.gotProductsLoaded = false
    },
    updateGotProductsCount(state, count) {
      state.gotProductsCount = count
    },
    updateGotProducts(state, products) {
      state.gotProducts = products
      state.gotProductsLoaded = true
    },
    updateGotProductsPage(state, page) {
      state.gotProductsPage = page
    },
    refrechDocuments(state) {
      state.documentsLoaded = false
    },
    updateDocumentsCount(state, count) {
      state.documentsCount = count
    },
    updateDocuments(state, documents) {
      state.documents = documents
      state.documentsLoaded = true
    },
    updateDocumentsPage(state, page) {
      state.documentsPage = page
    },
    refreshYurlitsa(state) {
      state.yurlitsaLoaded = false
    },
    updateYurlitsaCount(state, count) {
      state.yurlitsaCount = count
    },
    updateYurlitsa(state, yourlitsa) {
      state.yurlitsa = yourlitsa
      state.yurlitsaLoaded = true
    },
    updateYurlitsaPage(state, page) {
      state.yurlitsaPage = page
    },
    updateYurlitsoDef(state, { id , user}) {
      state.yurlitsa.forEach(element => {
        if (+element.id === +id) {
          element.default_use = 1
          user.def_your_name = element.name
          user.def_your_inn = element.inn
        } else {
          element.default_use = 0
        }
      });
    },
    updateYurlitso(state, data) {
      state.yurlitsa.forEach(element => {
        if (+element.id === +data.id) {
          element.name = data.name
          element.inn = data.inn
          element.ogrn = data.ogrn
          element.yur_address = data.yur_address
          element.rasch_sch = data.rasch_sch
          element.kpp = data.kpp
          element.bik = data.bik
          element.bank = data.bank
        }
      });
    },
    deleteYurlitso(state, id) {
      state.yurlitsa = state.yurlitsa.filter((yurlitso) => +yurlitso.id !== +id)
      state.yurlitsaCount--
    },
    addYurlitso(state, data) {
      delete(data.token)
      state.yurlitsa.push(data);
      state.yurlitsaCount++
    }
  },
  state: {
    documentsLoaded: false,
    documentsCount: 0,
    documents: [],
    documentsPage: 1,
    yurlitsaLoaded: false,
    yurlitsaCount: 0,
    yurlitsa: [],
    yurlitsaPage: 1,
    gotProductsLoaded: false,
    gotProductsCount: 0,
    gotProducts: [],
    gotProductsPage: 1
  },
  getters: {
    listDocumentsLoaded(state) {
      return state.documentsLoaded
    },
    listDocumentsCount(state) {
      return state.documentsCount
    },
    listDocuments(state) {
      return state.documents
    },
    listDocumentsPage(state) {
      return state.documentsPage
    },
    listGotProductsLoaded(state) {
      return state.gotProductsLoaded
    },
    listGotProductsCount(state) {
      return state.gotProductsCount
    },
    listGotProducts(state) {
      return state.gotProducts
    },
    listGotProductsPage(state) {
      return state.gotProductsPage
    },
    listYurlitsaLoaded(state) {
      return state.yurlitsaLoaded
    },
    listYurlitsaCount(state) {
      return state.yurlitsaCount
    },
    listYurlitsa(state) {
      return state.yurlitsa
    },
    listYurlitso: (state) => (id) => {
      return state.yurlitsa.filter((yurlitso) => +yurlitso.id === +id)[0]
    },
    listYurlitsaPage(state) {
      return state.yurlitsaPage
    },
  },
}