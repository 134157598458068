<template>
  <div
    :class="[
      'form-field phonemask-field',
      { hasValue: textValue },
      { invalid: v.$error },
      { valid: !v.$error && v.$dirty },
    ]"
  >
    <multiselect
      class="mask-select"
      v-model="countryCodeValue"
      :options="listSettings.phoneMasksArr"
      :allowEmpty="false"
      :deselectLabel="''"
      :searchable="false"
      :showLabels="false"
      label="flag"
      track-by="code"
    >
      <template slot="singleLabel" slot-scope="props">
        <img
          :src="'//' + serverLink + props.option.flag"
          :alt="props.option.code"
          style="width: 30px"
        />
      </template>
      <template slot="option" slot-scope="props">
        <img
          :src="'//' + serverLink + props.option.flag"
          :alt="props.option.code"
          style="width: 30px"
        />
        <div>
          <span>{{ props.option.code }}</span>

          {{ String(props.option.mask).replace(/\#/g, "_") }}
        </div>
      </template>
    </multiselect>
    <the-mask
      :mask="phoneMask"
      :masked="true"
      type="tel"
      :placeholder="phoneMask.replace(/\#/g, '_')"
      v-model.trim="textValue"
    />
    <label
      >{{ fieldLabel }}
      <span v-if="v.$params['required']" class="required">*</span>
    </label>
    <small :class="{ opened: errorMessage }" class="error-label">{{
      errorMessage
    }}</small>
  </div>
</template>

<script>
import {
  getValidationErrorMessage,
  //  validationDelay,
  defPhoneMask,
  VUE_APP_BACK_URL,
} from "@/config";
import { TheMask } from "vue-the-mask";
import Multiselect from "vue-multiselect";
import { mapGetters } from "vuex";

//const touchMap = new WeakMap();

export default {
  name: "PhoneField",
  components: { TheMask, Multiselect },
  data: () => {
    return {
      serverLink: VUE_APP_BACK_URL,
    };
  },
  props: {
    fieldLabel: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
    phoneMask: {
      type: String,
      default: defPhoneMask,
    },
    countryCode: {
      type: String,
      default: "RU",
    },
    v: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters(["translations", "listSettings"]),
    countryCodeValue: {
      get() {
        return this.listSettings.phoneMasksArr.find(
          (country) => country.code === this.$props.countryCode
        );
      },
      set(value) {
        this.textValue = "";
        console.log("value.code: ", value.code);
        this.$emit("code-change", value.code);
      },
    },
    textValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.v.$touch();
        /*
        if (touchMap.has(this.v)) {
          clearTimeout(touchMap.get(this.v));
        }
        touchMap.set(
          this.v,
          setTimeout(() => {
            this.v.$reset();
            this.v.$touch();
          }, validationDelay)
        );
        */
        this.$emit("input", value);
      },
    },
    errorMessage() {
      if (this.v.$error) {
        let msg = getValidationErrorMessage(this.v);
        return this.translations(msg.message, msg.params || []);
      }
      return "";
    },
  },
  methods: {},
};
</script>
