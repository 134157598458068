<template>
  <div>
    <header
      :class="[
        'scrolled',
        { dark: $route.meta.headerDark },
        { smallLogo: isNotFound },
      ]"
    >
      <div class="container">
        <div class="top-header">
          <div class="logo">
            <router-link to="/"><Logo /></router-link>
          </div>
          <div class="right-part">
            <TopMenu />
            <Cart />
            <div class="top-auth-telephone">
              <router-link
                class="header-btn header-login-btn"
                :class="[{ logged: isUserLogged }]"
                :to="!isUserLogged ? '/login' : '/account/profile'"
              >
                <span class="icon"
                  ><svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="12"
                      cy="8"
                      r="5"
                      stroke="currentcolor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 21C20 16.5817 16.4183 13 12 13C7.58172 13 4 16.5817 4 21"
                      stroke="currentcolor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M20 21C20 16.5817 16.4183 13 12 13C7.58172 13 4 16.5817 4 21"
                      stroke="currentcolor"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    /></svg
                ></span>
              </router-link>
              <LanguageChoose />
              <button
                type="button"
                class="header-mobile__hamburger"
                :class="{ active: isSandwichOpened }"
                @click="sandwichToggle()"
              >
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="main-layout-contents" :class="{ bigger: !isMainLayout }">
      <transition :name="$route.meta.transition" mode="out-in">
        <div class="page-slider" :key="$route.meta.key">
          <router-view />
        </div>
      </transition>
    </div>
    <transition name="fade" mode="out-in" appear>
      <footer
        :key="$route.meta.key"
        :class="[{ short: !isMainLayout }, { black: isNotFound }]"
      >
        <div class="container">
          <div class="footer-content">
            <div class="logo-part" v-if="!isNotFound">
              <router-link to="/"><Logo /></router-link>
              <div class="grey copyright-year" v-if="isMainLayout">
                © {{ listSettings.sitename }} 2005-{{
                  new Date() | date("year")
                }}
              </div>
              <p class="grey copyright" v-if="isMainLayout">
                {{ translations("AllRightsReserved") }}
              </p>
            </div>
            <div class="dev-logo-part" v-if="!isMainLayout">
              <DevLogo />
            </div>
            <div class="contacts-part" v-if="isMainLayout">
              <h3 class="grey">{{ translations("Contacts") }}</h3>
              <ContactsMenu />
            </div>
            <div class="menu-part" v-if="isMainLayout">
              <h3 class="grey">{{ translations("Menu") }}</h3>
              <BottomMenu />
            </div>
            <div class="social-part" :class="{ horizontal: !isMainLayout }">
              <button
                type="button"
                class="btn btn-white btn-big"
                @click="contactsPopupOpen()"
              >
                {{ translations("WriteAMessage") }}
              </button>
              <SocialMenu />
              <div class="grey copyright-year mob-visible" v-if="isMainLayout">
                © {{ listSettings.sitename }} 2005-{{
                  new Date() | date("year")
                }}
              </div>
              <p class="grey copyright mob-visible" v-if="isMainLayout">
                {{ translations("AllRightsReserved") }}
              </p>
              <DevLogo v-if="isMainLayout && false" />
            </div>
          </div>
          <Popuper v-if="isContactsPopupOpened" @close="contactsPopupClose()">
            <template v-slot:body>
              <ContactForm />
            </template>
            <template v-slot:footer></template>
          </Popuper>
        </div>
      </footer>
    </transition>
    <div
      class="mobile-sandwich-menu"
      :class="[{ active: isSandwichOpened }, { up: headerScrolled }]"
    >
      <TopMenu />
      <LanguageChoose />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Logo from "@/components/app/Logo";
import TopMenu from "@/components/app/TopMenu";
import BottomMenu from "@/components/app/BottomMenu";
import LanguageChoose from "@/components/app/LanguageChoose";
import DevLogo from "@/components/app/DevLogo";
import SocialMenu from "@/components/app/SocialMenu";
import ContactsMenu from "@/components/app/ContactsMenu";
import Cart from "@/components/app/Cart";
import Popuper from "@/components/app/Popuper";
import ContactForm from "@/components/app/forms/ContactForm";

export default {
  name: "main-layout",
  components: {
    TopMenu,
    Logo,
    LanguageChoose,
    BottomMenu,
    DevLogo,
    SocialMenu,
    ContactsMenu,
    Cart,
    Popuper,
    ContactForm,
  },
  data: () => ({
    headerScrolled: window.pageYOffset > 50,
  }),
  computed: {
    ...mapGetters([
      "listLogo",
      "listAppLanguage",
      "listLanguages",
      "listSettings",
      "isUserLogged",
      "translations",
      "isSettingsLoaded",
      "ListLoggedUser",
      "isSandwichOpened",
      "isContactsPopupOpened",
    ]),
    isMainLayout() {
      console.log(this.$route.meta.layout);
      return this.$route.meta.layout === "main-layout";
    },
    isNotFound() {
      return this.$route.meta.layout === "not-found-layout";
    },
  },
  watch: {
    isSandwichOpened(val) {
      if (val) {
        document.querySelector("body").classList.add("noscroll");
      } else {
        document.querySelector("body").classList.remove("noscroll");
      }
    },
  },
  methods: {
    ...mapActions([
      "fetchSettings",
      "makeLogout",
      "sandwichOpen",
      "sandwichClose",
      "sandwichToggle",
      "contactsPopupOpen",
      "contactsPopupClose",
    ]),
    onScroll() {
      if (window.pageYOffset > 50) {
        this.headerScrolled = true;
      } else {
        this.headerScrolled = false;
      }
    },
    handleClick(event) {
      let menu = document.querySelector(".mobile-sandwich-menu");
      let sandwBtn = document.querySelector(".header-mobile__hamburger");
      if (!menu.contains(event.target) && !sandwBtn.contains(event.target)) {
        this.sandwichClose();
      }
    },
  },
  mounted() {
    document.addEventListener("mouseup", this.handleClick);
    window.addEventListener("scroll", this.onScroll);
    if (!this.isSettingsLoaded) {
      this.fetchSettings(this.$cookies.get("user_language"));
    }
  },
  beforeDestroy() {
    document.removeEventListener("mouseup", this.handleClick);
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>