import Vue from 'vue'
import Vuex from 'vuex'
//import auth from './modules/auth'
import account from './modules/account'
import country from './modules/country'
import settings from './modules/settngs'
import page from './modules/page'
import typePurpose from './modules/typePurpose'
import productType from './modules/productType'
import faq from './modules/faq'
import firstDocs from './modules/firstDocs'
import firstAdvantage from './modules/firstAdvantage'
import firstPayOptions from './modules/firstPayOptions'
import reviews from './modules/reviews'
import article from './modules/article'
import product from './modules/product'
import company from './modules/company'
import cart from './modules/cart'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
//    auth,
    account,
    country,
    settings,
    page,
    typePurpose,
    productType,
    product,
    faq,
    firstDocs,
    firstAdvantage,
    firstPayOptions,
    reviews,
    article,
    company,
    cart,
  }
}) 