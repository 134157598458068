import { VUE_APP_BACK_URL } from '@/config'
import Vue from 'vue'
import 'whatwg-fetch'

export default {
	actions: {
		async fetchCart(context, { lang, customerId, cartIds = [] }) {
			let bodydata = new FormData()
			bodydata.append('lang', lang)
			bodydata.append('customer_id', customerId)
			bodydata.append('cart_ids[]', cartIds)
			const requestOptions = {
				method: 'POST',
				body: bodydata,
			}
			const response = await fetch(
				'//' + VUE_APP_BACK_URL + '/api/list-cart',
				requestOptions
			)
			if (response.ok) {
				// если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()
				if (body.cart) {
					// console.log(body.productTypes)
					context.commit('updateCart', body.cart)
				}
			} else {
				alert('Ошибка HTTP: ' + response.status)
			}
		},
		async addToCart(
			context,
			{ lang, customerId, company, productId, price, cartIds = [] }
		) {
			console.log('добавляем в корзину', cartIds)

			let bodydata = new FormData()
			bodydata.append('lang', lang)
			bodydata.append('customer_id', customerId)
			bodydata.append('cart_ids', [...cartIds])
			bodydata.append('company[id]', company.id)
			bodydata.append('company[name]', company.name)
			bodydata.append('company[inn]', company.inn)
			bodydata.append('company[status]', company.status)
			bodydata.append('company[type]', company.type)
			bodydata.append('company[country]', company.country)
			bodydata.append('company[address]', company.address)
			bodydata.append('product_id', productId)
			bodydata.append('price', price)
			const requestOptions = {
				method: 'POST',
				body: bodydata,
			}
			const response = await fetch(
				'//' + VUE_APP_BACK_URL + '/api/add-to-cart',
				requestOptions
			)
			if (response.ok) {
				// если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()
				console.log('body: ', body)
				if (body.cart) {
					// console.log(body.productTypes)
					context.commit('updateCart', body.cart)
				}
			} else {
				alert('Ошибка HTTP: ' + response.status)
			}
		},
		async deleteFromCart(context, { lang, customerId, cartId, cartIds = [] }) {
			let bodydata = new FormData()
			bodydata.append('lang', lang)
			bodydata.append('customer_id', customerId)
			bodydata.append('cart_ids', [...cartIds])
			bodydata.append('cart_id', cartId)
			const requestOptions = {
				method: 'POST',
				body: bodydata,
			}
			const response = await fetch(
				'//' + VUE_APP_BACK_URL + '/api/remove-from-cart',
				requestOptions
			)
			if (response.ok) {
				// если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()
				if (body.cart) {
					console.log('cart: ', body.cart)
					context.commit('updateCart', body.cart)
				}
			} else {
				alert('Ошибка HTTP: ' + response.status)
			}
		},
		clearCart(context) {
			context.commit('doClearCart')
		},
		async saveOrder(
			context,
			{ lang, customerId, cartIds, checkoutData, token, clientID }
		) {
			console.log('сохраняем заказ')

			let bodydata = new FormData()
			bodydata.append('lang', lang)
			bodydata.append('customer_id', customerId)
			bodydata.append('cart_ids', [...cartIds])
			bodydata.append('first_name', checkoutData.firstName)
			bodydata.append('last_name', checkoutData.lastName)
			bodydata.append('middle_name', checkoutData.middleName)
			bodydata.append('email', checkoutData.email)
			bodydata.append('phone', checkoutData.phone)
			bodydata.append('yur_name', checkoutData.yurName)
			bodydata.append('yur_inn', checkoutData.yurInn)
			bodydata.append('delivery_country', checkoutData.country.value)
			bodydata.append('delivery_region', checkoutData.region)
			bodydata.append('delivery_city', checkoutData.city)
			bodydata.append('delivery_address', checkoutData.address)
			bodydata.append('contact_name', checkoutData.contactname)
			bodydata.append('contact_phone', checkoutData.contactphone)
			bodydata.append('contact_index', checkoutData.contactindex)
			bodydata.append('payment_method', checkoutData.paymentMethod)
			bodydata.append('comment', checkoutData.comment)
			bodydata.append('token', token)
			bodydata.append('clientID', clientID)
			const requestOptions = {
				method: 'POST',
				body: bodydata,
			}
			const response = await fetch(
				'//' + VUE_APP_BACK_URL + '/api/save-order',
				requestOptions
			)
			if (response.ok) {
				// если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()

				console.log('body', body)

				if (body.user) {
					Vue.prototype.$cookies.remove('user_token')
					Vue.prototype.$cookies.set(
						'user_token',
						body.user.auth_key,
						60 * 60 * 24 * 1
					)
					context.commit('loginSuccess', body.user)
				}

				if (body.order) {
					// console.log(body.productTypes)
					context.commit('saveOrder', body.order)
				}
				if (body.payment_form) {
					// console.log(body.productTypes)
					context.commit('setPaymentForm', body.payment_form)
				}
			} else {
				const body = await response.json()

				console.log('body', body)

				if (body.userExists) {
					context.commit('setUserExists')
				}

				if (body.error) {
					throw new Error(body.error)
				} else {
					throw new Error('ErrorSavingOrder')
				}
			}
		},
		async fetchOrder(context, { lang, orderId }) {
			let bodydata = new FormData()
			bodydata.append('lang', lang)
			const requestOptions = {
				method: 'POST',
				body: bodydata,
			}
			const response = await fetch(
				'//' + VUE_APP_BACK_URL + '/api/get-order?order_id=' + orderId,
				requestOptions
			)
			if (response.ok) {
				// если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()

				console.log('body', body)

				if (body.order) {
					context.commit('loadOrder', body.order)
				}
			} else {
				const body = await response.json()
				if (body.error) {
					throw new Error(body.error)
				} else {
					throw new Error('Error fetching order')
				}
			}
		},
		clearOrder(context) {
			context.commit('doClearOrder')
		},
	},
	mutations: {
		updateCart(state, cart) {
			state.cartItems = cart
			if (!state.isLogged && state.cartItems.length) {
				let cart_ids = []
				state.cartItems.forEach((item) => {
					cart_ids.push(item.id)
				})
				localStorage.setItem('cart_ids', '[' + cart_ids.join(',') + ']')
			} else if (!state.isLogged && !state.cartItems.length) {
				console.log('пусто в корзине')
				localStorage.setItem('cart_ids', '[]')
			}
			state.cartLoaded = true
			state.userExists = false
		},
		doClearCart(state) {
			state.cartItems = []
			localStorage.setItem('cart_ids', '[]')
			state.userExists = false
		},
		saveOrder(state, order) {
			state.order = order
			state.orderCreated = true
		},
		setUserExists(state) {
			state.userExists = true
		},
		loadOrder(state, order) {
			state.order = order
			state.orderloaded = true
		},
		setPaymentForm(state, form) {
			state.paymentForm = form
			state.paymentFormloaded = true
		},
		doClearOrder(state) {
			state.orderCreated = false
			state.order = {}
			state.paymentFormloaded = false
			state.paymentForm = {}
			state.userExists = false
		},
	},
	state: {
		cartLoaded: false,
		cartItems: [],

		// флаг, указывающий что после логина нужно снова попасть на оформление
		checkoutReturning: false,

		orderloaded: false,
		orderCreated: false,
		userExists: false,
		order: {},
		paymentFormloaded: false,
		paymentForm: {},
	},
	getters: {
		listCart(state) {
			return state.cartItems
		},
		listCartTotal(state) {
			let sum = 0
			state.cartItems.forEach((item) => {
				sum += parseFloat(item.product_price_num)
			})
			return sum
		},
		isDeliveryRequired(state) {
			return state.cartItems.filter((item) => item.delivery === true).length
		},
		listCartDuration(state) {
			let duration = { from: 9999999999999, to: 0 }

			let saved_duration = { from: '', to: '' }
			let different = false

			state.cartItems.forEach((item) => {
				if (saved_duration.from === '') {
					saved_duration.from = item.product_duration.from
				}
				if (saved_duration.to === '') {
					saved_duration.to = item.product_duration.to
				}

				if (
					saved_duration.from !== '' &&
					saved_duration.from !== item.product_duration.from
				) {
					different = true
				}
				if (
					saved_duration.to !== '' &&
					saved_duration.to !== item.product_duration.to
				) {
					different = true
				}

				if (item.product_duration.from < duration.from) {
					duration.from = item.product_duration.from
				}
				if (item.product_duration.to > duration.to) {
					duration.to = item.product_duration.to
				}
			})
			const text =
				duration.from !== duration.to
					? duration.from + '-' + duration.to
					: duration.from
			if (different) {
				return {
					to: '',
					text: '',
				}
			} else {
				return {
					to: duration.to,
					text,
				}
			}
		},
		isCartLoaded(state) {
			return state.cartLoaded
		},
		cartButtonDisabled: (state) => (companyName, productId) => {
			let found = state.cartItems.find(
				(ci) => ci.company_name === companyName && ci.product_id === +productId
			)
			return found
		},
		isOrderCreated(state) {
			return state.orderCreated
		},
		isOrderLoaded(state) {
			return state.orderloaded
		},
		isUserExists(state) {
			return state.userExists
		},
		listOrder(state) {
			return state.order
		},
		isPaymentFormLoaded(state) {
			return state.paymentFormloaded
		},
		listPaymentForm(state) {
			return state.paymentForm
		},
	},
}
