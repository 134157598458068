<template>
  <div class="btn-group">
    <li
      @click="toggleMenu()"
      class="dropdown-toggle"
      :class="[{ opened: showMenu }]"
      v-if="selectedOptionName !== undefined"
    >
      {{ selectedOptionName }}
      <span class="caret"></span>
    </li>

    <ul class="dropdown-menu" v-if="showMenu">
      <li v-for="item in options" :key="item.locale">
        <a href="javascript:void(0)" @click="updateOption(item)">
          {{ item.name }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      selectedOption: "",
      selectedOptionName: "",
      showMenu: false,
    };
  },
  props: {
    options: {
      type: [Array, Object],
    },
    selected: [String],
  },
  mounted() {
    this.selectedOption = this.selected;
    this.selectedOptionName = this.options.filter(
      (option) => option.locale === this.selected
    );
    this.selectedOptionName = this.selectedOptionName[0].name;
    document.addEventListener("click", this.clickHandler);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.clickHandler);
  },
  methods: {
    updateOption(option) {
      this.selectedOption = option.locale;
      this.selectedOptionName = option.name;
      this.showMenu = false;
      this.$emit("updateOption", this.selectedOption);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    clickHandler(event) {
      const { target } = event;
      const { $el } = this;
      if (!$el.contains(target)) {
        this.showMenu = false;
      }
    },
  },
};
</script>

<style scoped>
.btn-group {
  height: 28px;
  position: relative;
  display: inline-block;
  vertical-align: middle;
}
.btn-group a:hover {
  text-decoration: none;
}
.dropdown-toggle {
  color: inherit;
  padding: 5px 15px 5px 5px;
  text-transform: none;
  border: 0;
  background-color: transparent;
  transition: background 0.3s ease;
  float: none;
  box-shadow: none;
  border-radius: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;
  transition: 0.3s all ease;
}
@media (max-width: 420px) {
  header .btn-group {
    height: 43px;
  }
  header .dropdown-toggle {
    padding: 5px 5px 20px 5px;
  }
}
.dropdown-toggle:hover {
  color: var(--linkColor);
}
header.dark:not(.scrolled) .dropdown-toggle:hover {
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1000;
  float: right;
  min-width: 60px;
  padding: 5px 0;
  margin: 2px 0 0;
  list-style: none;
  font-size: 14px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  background-clip: padding-box;
}
.mobile-sandwich-menu .dropdown-menu {
  float: left;
  top: auto;
  bottom: 100%;
  right: auto;
  left: 0;
}
.dropdown-menu > li > a {
  padding: 10px 30px;
  display: block;
  clear: both;
  font-weight: normal;
  line-height: 1.6;
  color: #333333;
  white-space: nowrap;
  text-decoration: none;
}
.dropdown-menu > li > a:hover {
  background: #efefef;
  color: #4c69e8;
}
.dropdown-menu > li {
  overflow: hidden;
  width: 100%;
  position: relative;
  margin: 0;
}
.caret {
  width: 0;
  position: absolute;
  top: 50%;
  height: 0;
  right: 0;
  width: 12px;
  height: 12px;
  border-radius: 12px;
  transform: translateY(-50%) rotate(0deg);
  transition: all 0.3s ease;
}
.opened .caret {
  transform: translateY(-50%) rotate(180deg);
}
.caret:after {
  content: "";
  position: absolute;
  right: 2px;
  top: 0;
  width: 6px;
  height: 6px;
  border-bottom: 2px solid currentColor;
  border-left: 2px solid currentColor;
  transform: rotate(-45deg);
  transition: all 0.3s ease;
}
@media (max-width: 420px) {
  header .caret {
    top: auto;
    bottom: 3px;
    left: 50%;
    transform: translateY(0) translateX(-50%);
  }
}

li {
  list-style: none;
}
</style>