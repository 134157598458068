import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchProducts(context, {lang, curCountry}) {
      let bodydata = new FormData();
      bodydata.append('country_id', +curCountry)
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/products', requestOptions)
      if (response.ok) {
        const body = await response.json()
        console.log('load all data')
        if (body.products) {
          // console.log(body.productTypes)
          context.commit('updateProducts', body.products)
        }
        if (body.typePurposes) {
          // console.log(body.productTypes)
          context.commit('updateTypePurposes', body.typePurposes)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    async fetchPriceList(context, {lang, countryId}) {
      let userdata = new FormData();
      userdata.append('country_id', countryId)
      userdata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/price-list', requestOptions)
      console.log('response: ', response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'pricelist.pdf';

        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
          link.remove();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingPriceList");
        }
      }
    },
    refreshProducts(context) {
      context.commit('doRefreshProducts')
    },
    toggleProductOpen(context, productId) {
      context.commit('updateProductOpen', productId)
    },
    setCurProductType(context, curProductType) {
      context.commit('updateCurProductType', curProductType)
    },
    setCurProduct(context, curProduct) {
      context.commit('updateCurProduct', curProduct)
    },
  },
  mutations: {
    updateProducts(state, products) {
      state.products = products
      state.productsLoaded = true
    },
    updateProductOpen(state, productId) {
      let found = state.products.find((product) => product.id === productId)
      found.open = !found.open
    },
    updateCurProductType(state, curProductType) {
      state.curProductType = curProductType
    },
    updateCurProduct(state, curProduct) {
      state.curProduct = curProduct
    },
    doRefreshProducts(state) {
      state.productsLoaded = false
    },
  },
  state: {
    productsLoaded: false,
    products: [],
    curProductType: 0,
    curProduct: 0,
  },
  getters: {
    productsLoaded(state) {
      return state.productsLoaded
    },
    isProductOpen: (state) => productId => {
      let found = state.products.find((product) => product.id === productId)
      return found.open
    },
    selectProduct: (state) => productId => {
      return state.products.find((product) => product.id === +productId)
    },
    listProducts: (state) => (productType = 0) => {
      if (productType) {
        return state.products.filter((product) => product.productTypes.includes(productType))
      }
      return state.products
		},
    listRelatedProducts: (state) => (productId) => {
      const relateds = state.products.find((product) => product.id === +productId).productsRelated
      return state.products.filter((p) => relateds.includes(p.id))
		},
    listCurTypeProducts(state) {
      return state.products.filter((p) => p.productTypes.includes(state.curProductType))
		},
    curProductTypeId(state) {
      return state.curProductType
		},
    curProductType(state, getters) {
      return getters.curTypePurpose.productTypes.find((pt) => pt.id === state.curProductType)
		},
    listCurProductId(state) {
      return state.curProduct
		},
    listCurProduct(state) {
      return state.products.find((p) => p.id === state.curProduct)
		},
  }
}