import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchFirstPayOptions(context, lang) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/first-pay-options', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.pay_options) {
          console.log(body.pay_options)
          context.commit('updatePayOptions', body.pay_options)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
  },
  mutations: {
    updatePayOptions(state, pay_options) {
      state.payOptions = pay_options
      state.payOptionsLoaded = true
    },
  },
  state: {
    payOptionsLoaded: false,
    payOptions: [],
  },
  getters: {
    firstPayOptionsLoaded(state) {
      return state.payOptionsLoaded
    },
    listFirstPayOptions(state) {
      return state.payOptions
    },
  }
}