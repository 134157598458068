import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchProductTypes(context, {type_purpose_id, lang}) {
      type_purpose_id = +type_purpose_id;
      let bodydata = new FormData();
      bodydata.append('type_purpose_id', type_purpose_id)
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/product-types', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.productTypes) {
          // console.log(body.productTypes)
          context.commit('updateProductTypes', body.productTypes)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
  },
  mutations: {
    updateProductTypes(state, productTypes) {
      state.productTypes = productTypes
      state.productTypesLoaded = true
    },
  },
  state: {
    productTypesLoaded: false,
    productTypes: [],
  },
  getters: {
    productTypesLoaded(state) {
      return state.productTypesLoaded
    },
    listProductTypes(state) {
      return state.productTypes
    },
  }
}