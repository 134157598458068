import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchFaqs(context, lang) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/faqs', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.faqs) {
          //console.log(body.typePurposes)
          context.commit('updateFaqs', body.faqs)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    setCurFaq(context, curFaq) {
      context.commit('updateCurFaq', curFaq)
    },
		refreshFaqs(context) {
			context.commit('faqsLoaded', false)
		}
  },
  mutations: {
    updateFaqs(state, faqs) {
      state.faqs = faqs
      state.faqsLoaded = true
      state.curFaq = 0
    },
    updateCurFaq(state, curFaq) {
      state.curFaq = +state.curFaq !== +curFaq ? curFaq : 0
    }, 
		faqsLoaded(state, loaded) {
			if (!loaded) {
				state.faqs = []
				state.curFaq = 0
			}
			state.faqsLoaded = loaded
		},
  },
  state: {
    faqsLoaded: false,
    faqs: [],
    curFaq: 0,
  },
  getters: {
    faqsLoaded(state) {
      return state.faqsLoaded
    },
    curFaq(state) {
      return state.curFaq
    },
    listFaqs(state) {
      return state.faqs
    },
  }
}