<template>
  <div id="language">
    <LangDropdown
      :options="listLanguages"
      :selected="listAppLanguage"
      @updateOption="languageSelect"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LangDropdown from "@/components/app/LangDropdown";

export default {
  name: "top-menu",
  components: {
    LangDropdown,
  },
  computed: {
    ...mapGetters(["listLanguages", "listAppLanguage"]),
  },
  methods: {
    async languageSelect(newValue) {
      if (this.$cookies.isKey("user_language")) {
        this.$cookies.remove("user_language");
      }
      this.$cookies.set("user_language", newValue);
      console.log(newValue);

      await this.$store.dispatch("refreshSettings");
      await this.$store.dispatch("refreshCountries");
      //      await this.$store.dispatch("refreshTypePurposes");
      await this.$store.dispatch("clearUser");
      await this.$store.dispatch(
        "fetchSettings",
        this.$cookies.get("user_language")
      );
    },
  },
};
</script>