<template>
  <div
    class="social-menu"
    v-if="listSocial.vk || listSocial.tw || listSocial.fb || listSocial.in"
  >
    <a :href="listSocial.vk" v-if="listSocial.vk">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.47708 2 2 6.47708 2 12C2 17.5229 6.47708 22 12 22C17.5229 22 22 17.5229 22 12C22 6.47708 17.5229 2 12 2ZM15.8458 13.2823C15.8458 13.2823 16.7302 14.1552 16.9479 14.5604C16.9542 14.5688 16.9573 14.5771 16.9594 14.5812C17.0479 14.7302 17.0688 14.8458 17.025 14.9323C16.9521 15.076 16.7021 15.1469 16.6167 15.1531H15.0542C14.9458 15.1531 14.7188 15.125 14.4437 14.9354C14.2323 14.7875 14.024 14.5448 13.8208 14.3083C13.5177 13.9562 13.2552 13.6521 12.9906 13.6521C12.957 13.652 12.9236 13.6573 12.8917 13.6677C12.6917 13.7323 12.4354 14.0177 12.4354 14.7781C12.4354 15.0156 12.2479 15.1521 12.1156 15.1521H11.4C11.1562 15.1521 9.88646 15.0667 8.76146 13.8802C7.38437 12.4271 6.14479 9.5125 6.13437 9.48542C6.05625 9.29687 6.21771 9.19583 6.39375 9.19583H7.97187C8.18229 9.19583 8.25104 9.32396 8.29896 9.4375C8.35521 9.56979 8.56146 10.0958 8.9 10.6875C9.44896 11.6521 9.78542 12.0437 10.0552 12.0437C10.1058 12.0432 10.1555 12.0303 10.2 12.0062C10.5521 11.8104 10.4865 10.5552 10.4708 10.2948C10.4708 10.2458 10.4698 9.73333 10.2896 9.4875C10.1604 9.30937 9.94062 9.24167 9.80729 9.21667C9.86126 9.1422 9.93236 9.08182 10.0146 9.04062C10.2562 8.91979 10.6917 8.90208 11.124 8.90208H11.3646C11.8333 8.90833 11.9542 8.93854 12.124 8.98125C12.4677 9.06354 12.475 9.28542 12.4448 10.0448C12.4354 10.2604 12.426 10.5042 12.426 10.7917C12.426 10.8542 12.4229 10.9208 12.4229 10.9917C12.4125 11.3781 12.4 11.8167 12.6729 11.9969C12.7085 12.0192 12.7497 12.0311 12.7917 12.0312C12.8865 12.0312 13.1719 12.0312 13.9448 10.7052C14.1832 10.2784 14.3903 9.83487 14.5646 9.37812C14.5802 9.35104 14.626 9.26771 14.6802 9.23542C14.7202 9.21504 14.7645 9.20467 14.8094 9.20521H16.6646C16.8667 9.20521 17.0052 9.23542 17.0312 9.31354C17.0771 9.4375 17.0229 9.81562 16.176 10.9625L15.7979 11.4615C15.0302 12.4677 15.0302 12.5188 15.8458 13.2823Z"
          fill="#7D7E8B"
        />
      </svg>
    </a>
    <a :href="listSocial.tw" v-if="listSocial.tw">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.47768 2 2 6.47768 2 12C2 17.5223 6.47768 22 12 22C17.5223 22 22 17.5223 22 12C22 6.47768 17.5223 2 12 2ZM16.8058 9.53795C16.8125 9.64286 16.8125 9.75223 16.8125 9.85938C16.8125 13.1362 14.317 16.9107 9.7567 16.9107C8.35045 16.9107 7.04688 16.5022 5.94866 15.7991C6.14955 15.8214 6.34152 15.8304 6.54688 15.8304C7.70759 15.8304 8.77455 15.4375 9.625 14.7723C8.53571 14.75 7.62054 14.0357 7.30804 13.0536C7.68973 13.1094 8.03348 13.1094 8.42634 13.0089C7.86546 12.895 7.36133 12.5904 6.99959 12.1468C6.63785 11.7033 6.44082 11.1482 6.44196 10.5759V10.5446C6.77009 10.7299 7.15625 10.8438 7.56027 10.8594C7.22063 10.633 6.94209 10.3264 6.74936 9.96658C6.55662 9.60681 6.45563 9.20503 6.45536 8.79688C6.45536 8.33482 6.57589 7.91295 6.79241 7.54688C7.41496 8.31325 8.19181 8.94005 9.07247 9.38653C9.95312 9.83301 10.9179 10.0892 11.904 10.1384C11.5536 8.45313 12.8125 7.08929 14.3259 7.08929C15.0402 7.08929 15.683 7.38839 16.1362 7.87054C16.6964 7.76562 17.2321 7.5558 17.7098 7.27455C17.5246 7.84821 17.1362 8.33259 16.6205 8.63839C17.1205 8.58482 17.6027 8.44643 18.0491 8.25223C17.7121 8.74777 17.2902 9.1875 16.8058 9.53795Z"
          fill="#7D7E8B"
        />
      </svg>
    </a>
    <a :href="listSocial.fb" v-if="listSocial.fb">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12.001 2.00201C6.47895 2.00201 2.00195 6.47901 2.00195 12.001C2.00195 16.991 5.65795 21.127 10.439 21.88V14.892H7.89895V12.001H10.439V9.79801C10.439 7.29001 11.932 5.90701 14.215 5.90701C15.309 5.90701 16.455 6.10201 16.455 6.10201V8.56101H15.191C13.951 8.56101 13.563 9.33301 13.563 10.124V11.999H16.334L15.891 14.89H13.563V21.878C18.344 21.129 22 16.992 22 12.001C22 6.47901 17.523 2.00201 12.001 2.00201Z"
          fill="#7D7E8B"
        />
      </svg>
    </a>
    <a :href="listSocial.in" v-if="listSocial.in">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.9973 8.66525C10.1611 8.66525 8.66262 10.1638 8.66262 12C8.66262 13.8362 10.1611 15.3348 11.9973 15.3348C13.8335 15.3348 15.332 13.8362 15.332 12C15.332 10.1638 13.8335 8.66525 11.9973 8.66525ZM21.9989 12C21.9989 10.6191 22.0114 9.25064 21.9338 7.87221C21.8563 6.27113 21.4911 4.85017 20.3203 3.67938C19.147 2.50609 17.7286 2.14334 16.1275 2.06579C14.7466 1.98824 13.3782 2.00074 11.9998 2.00074C10.6189 2.00074 9.25051 1.98824 7.8721 2.06579C6.27105 2.14334 4.85012 2.50859 3.67935 3.67938C2.50608 4.85267 2.14334 6.27113 2.06579 7.87221C1.98824 9.25314 2.00074 10.6216 2.00074 12C2.00074 13.3784 1.98824 14.7494 2.06579 16.1278C2.14334 17.7289 2.50858 19.1498 3.67935 20.3206C4.85262 21.4939 6.27105 21.8567 7.8721 21.9342C9.25301 22.0118 10.6214 21.9993 11.9998 21.9993C13.3807 21.9993 14.7491 22.0118 16.1275 21.9342C17.7286 21.8567 19.1495 21.4914 20.3203 20.3206C21.4936 19.1473 21.8563 17.7289 21.9338 16.1278C22.0139 14.7494 21.9989 13.3809 21.9989 12ZM11.9973 17.131C9.15795 17.131 6.86644 14.8394 6.86644 12C6.86644 9.16058 9.15795 6.86903 11.9973 6.86903C14.8367 6.86903 17.1282 9.16058 17.1282 12C17.1282 14.8394 14.8367 17.131 11.9973 17.131ZM17.3383 7.8572C16.6754 7.8572 16.14 7.32184 16.14 6.65889C16.14 5.99594 16.6754 5.46058 17.3383 5.46058C18.0013 5.46058 18.5366 5.99594 18.5366 6.65889C18.5368 6.81631 18.5059 6.97222 18.4458 7.1177C18.3856 7.26317 18.2974 7.39535 18.1861 7.50666C18.0748 7.61798 17.9426 7.70624 17.7971 7.76639C17.6516 7.82654 17.4957 7.8574 17.3383 7.8572Z"
          fill="#7D7E8B"
        />
      </svg>
    </a>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "SocialMenu",
  computed: {
    ...mapGetters(["listSocial"]),
  },
};
</script>