<template>
  <div id="nav">
    <router-link
      v-for="item in listTopMenu"
      :class="[
        { 'desktop-hidden': !item.top_status },
        { 'hamburger-hidden': !item.hamburger_status },
      ]"
      :key="item.url"
      :to="item.url"
    >
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "top-menu",
  computed: {
    ...mapGetters(["listTopMenu"]),
  },
};
</script>