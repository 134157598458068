import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchArticles(context, {lang, categoryId, pagesize, page}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('pagesize', pagesize)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/articles?page='+page+'&category_id='+categoryId, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.articles) {
          console.log(body.articles)
          context.commit('updateArticlesCount', body.articles.count);
          context.commit('updateArticles', body.articles.list)
          context.commit('updateArticlesPage', body.articles.page);
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    async fetchArticle(context, {lang, path}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('path', path)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/article', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.articles) {
          console.log(body.articles)
          context.commit('updateCurArticle', body.articles.current)
          context.commit('updateCurArticleCountry', body.articles.country)
          context.commit('updateRecentArticles', body.articles.recent_list)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    clearArticles(context) {
      context.commit('clearArticles');
    },
    clearArticle(context) {
      context.commit('clearArticle');
    },
  },
  mutations: {
    clearArticles(state) {
      state.articlesLoaded = false
      state.articlesCount = 0
      state.articles = []
      state.articlesPage = 1
    },
    clearArticle(state) {
      state.curArticle = []
      state.recentArticles = []
      state.curArticleCountry = null
    },
    updateArticlesCount(state, count) {
      state.articlesCount = count
    },
    updateArticles(state, articles) {
      state.articles = articles
      state.articlesLoaded = true
    },
    updateRecentArticles(state, articles) {
      state.recentArticles = articles
    },
    updateCurArticle(state, article) {
      state.curArticle = article
    },
    updateCurArticleCountry(state, country) {
      state.curArticleCountry = country ? country : null
    },
    updateArticlesPage(state, page) {
      state.articlesPage = page
    },
  },
  state: {
    articlesLoaded: false,
    articlesCount: 0,
    articles: [],
    articlesPage: 1,
    curArticle: [],
    curArticleCountry: null,
    recentArticles: [],
  },
  getters: {
    isArticlesLoaded(state) {
      return state.articlesLoaded
    },
    listArticlesCount(state) {
      return state.articlesCount
    },
    listArticles(state) {
      return state.articles
    },
    listRecentArticles(state) {
      return state.recentArticles
    },
    listArticlesPage(state) {
      return state.articlesPage
    },
    listCurArticle(state) {
      return state.curArticle
    },
    listCurArticleCountry(state) {
      return state.curArticleCountry
    },
  }
}