import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchPage(context, {link, lang}) {
      console.log(link)
      link = link.replace('/page', '')
      let bodydata = new FormData();
      bodydata.append('link', link)
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/page', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.page) {
          //console.log(body.page)
          context.commit('updatePage', body.page)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
  },
  mutations: {
    updatePage(state, page) {
      if (page.title) {
        state.page = page
        let text = state.page.text
        let find = '/upload/image/'
        let re = new RegExp(find, 'g');
        text = text.replace(re, '//' + VUE_APP_BACK_URL + find);
        state.page.text = text

        text = state.page.image
        text = text.replace(re, '//' + VUE_APP_BACK_URL + find);
        state.page.image = text
      } else {
        state.page = {}
      }
    },
  },
  state: {
    pages: [],
    page: [],
  },
  getters: {
    listPage(state) {
      return state.page
    },
},
}