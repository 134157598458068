<template>
  <form
    class="submit-form"
    :class="$props.formClass"
    @submit.prevent="doFormValidate()"
  >
    <p v-if="formTitle" class="subtitle">
      {{ formTitle }}
    </p>
    <slot />
    <p
      :class="[
        'message',
        { success: submitStatus === 'OK' },
        { error: submitStatus === 'ERROR' },
      ]"
    >
      {{ formMessage }}
    </p>
    <vue-recaptcha
      ref="recaptcha"
      size="invisible"
      badge="bottomright"
      :loadRecaptchaScript="true"
      :sitekey="listSettings.recaptchaSite"
      @verify="doSubmit"
      @expired="onCaptchaExpired"
    />
    <slot name="submit" :status="submitStatus">
      <button
        class="btn btn-blue btn-big btn-block"
        :class="{ pending: submitStatus === 'PENDING' }"
        type="submit"
        :disabled="submitStatus === 'PENDING'"
      >
        <Loader v-if="submitStatus === 'PENDING'" :color="'white'" />
        <span>{{ formBtnText }}</span>
      </button>
    </slot>
    <slot name="privacy"></slot>
  </form>
</template>

<script>
import Loader from "@/components/app/Loader";
import { mapGetters } from "vuex";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "FormWrapper",
  props: {
    formTitle: {
      type: String,
      default: "",
    },
    formButton: {
      type: String,
      default: "",
    },
    formClass: {
      type: String,
      default: "login-form",
    },
    v: {
      type: Object,
      required: true,
    },
    formCallback: Function,
    successMessage: {
      type: String,
      required: true,
    },
  },
  data: () => {
    return {
      submitStatus: "",
      errorMessage: "",
      formBtnText: "",
    };
  },
  components: { VueRecaptcha, Loader },
  computed: {
    ...mapGetters(["translations", "listSettings"]),
    formMessage() {
      if (this.submitStatus === "OK") {
        return this.successMessage;
      }
      if (this.submitStatus === "PENDING") {
        return this.translations("SendingData");
      }
      if (this.submitStatus === "ERROR") {
        return this.translations(this.errorMessage);
      }
      return "";
    },
  },
  methods: {
    doFormValidate() {
      console.log("FormWrapper - doFormValidate");
      if (this.v.$invalid) {
        this.v.$touch();
        this.submitStatus = "ERROR";
        this.errorMessage = "";
        return;
      }
      this.$refs.recaptcha.execute();
    },
    onCaptchaExpired() {
      this.$refs.recaptcha.reset();
    },
    async doSubmit(recaptchaToken) {
      console.log("FormWrapper - doSubmit");
      try {
        this.submitStatus = "PENDING";
        await this.formCallback(recaptchaToken);
        this.submitStatus = "OK";
        this.$emit("form-success");
      } catch (err) {
        this.submitStatus = "ERROR";
        this.errorMessage = this.translations(err.message);
        console.error(err);
      }
    },
  },
  mounted() {
    if (!this.formButton) {
      this.formBtnText = this.translations("Submit");
    } else {
      this.formBtnText = this.formButton;
    }
  },
};
</script>