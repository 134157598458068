<template>
  <div class="lds-dual-ring" :style="{'color': $props.color}">
    <img
      class="icon"
      src="data:image/svg+xml;utf8,&lt;svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1 1'&gt;&lt;/svg&gt;"
    />
  </div>
</template>

<script>
export default {
  name: "Loader",
  props: {
    color: {
      type: String,
      default: "var(--blackColor)",
    },
  },
};
</script>