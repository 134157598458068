import { VUE_APP_BACK_URL, defPhoneMask } from '@/config';
import Vue from 'vue'
import router from '@/router'
import expiringStorage from "@/expiringStorage";
import 'whatwg-fetch'

export default {
	actions: {

		// Settings
		async fetchSettings(context, lang = '') {

//			if (!lang) {lang = 'ru-RU'}
			if (!lang) {
				lang = Vue.prototype.$cookies.get('user_language')
			}
			let token = Vue.prototype.$cookies.get('user_token')
			const manager_token = expiringStorage.get('manager_token')
			const manager_user_id = expiringStorage.get('manager_user_id')

			if (typeof lang === 'object' && lang !== null) {
				lang = JSON.parse(decodeURIComponent(lang));
				console.log('lang new', lang)
      }
      
      let cartIds = []
      if (!token) {
        cartIds = localStorage.getItem("cart_ids");
        cartIds = JSON.parse(cartIds);
        console.log('cartIds: ', cartIds)
      }

			let bodydata = new FormData();
			bodydata.append('lang', lang)
      bodydata.append('auth_key', token)
      if (!token) {
        bodydata.append('cart_ids', cartIds)
      }
      if (manager_token && manager_user_id) {
        bodydata.append('manager_auth_key', manager_token)
        bodydata.append('manager_user_id', manager_user_id)
      } else {
        bodydata.append('clientID', context.state.user.clientID)
      }

			const requestOptions = {
				method: 'POST',
				body: bodydata
			};

			const response = await fetch('//' + VUE_APP_BACK_URL + '/api/settings', requestOptions)
			if (response.ok) { // если HTTP-статус в диапазоне 200-299
				// получаем тело ответа (см. про этот метод ниже)
				const body = await response.json()
				console.log('body', body)
				if (body.settings) {
					context.commit('updateSettings', body.settings)
				}
				if (body.top_menu) {
					context.commit('updateTopMenu', body.top_menu)
				}
				if (body.bottom_menu) {
					context.commit('updateBottomMenu', body.bottom_menu)
				}
				if (body.lang_settings) {
					context.commit('updateLang', body.lang_settings)
				}
				if (body.translations) {
					context.commit('updateTranslations', body.translations)
				}
				if (body.social) {
					context.commit('updateSocial', body.social)
				}
				if (body.languages) {
					context.commit('updateLanguages', body.languages)
				}
				if (body.cart) {
					context.commit('updateCart', body.cart)
				}
				context.commit('settingsLoaded', true)

				if (body.user_data) {
					Vue.prototype.$cookies.remove('user_token');
					Vue.prototype.$cookies.set('user_token', body.user_data.auth_key, 60 * 60 * 24 * 1);
          context.commit('loginSuccess', body.user_data);
        
          if (body.user_data.clientID && Vue.prototype.$metrika) {
            Vue.prototype.$metrika.setUserID(body.user_data.clientID)
          }

				} else {
					context.commit('doLogout');
				}

			} else {
				alert("Ошибка HTTP: " + response.status);
			}
		},
		refreshSettings(context) {
			context.commit('settingsLoaded', false)
		},

		// login | autologin
    async login(context, user) {
      let logindata = new FormData();
      logindata.append('LoginForm[username]', user.email)
      logindata.append('LoginForm[password]', user.password)
      logindata.append('LoginForm[rememberMe]', parseInt(user.rememberMe))
      logindata.append('cart_ids', user.cartIds)
      logindata.append('clientID', context.state.user.clientID)

      const requestOptions = {
        method: 'POST',
        body: logindata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/login', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.user) {
          console.log(body.user)
          if (user.rememberMe) {
            Vue.prototype.$cookies.set('user_token', body.user.auth_key, 60 * 60 * 24 * 30);
            localStorage.setItem('rememberMe', true);
          } else {
            Vue.prototype.$cookies.set('user_token', body.user.auth_key, 60 * 60 * 24 * 1);
            localStorage.setItem('rememberMe', false);
          }
          context.commit('loginSuccess', body.user);

          if (body.user.clientID && Vue.prototype.$metrika) {
            Vue.prototype.$metrika.setUserID(body.user.clientID)
          }

        }
				if (body.cart) {
					context.commit('updateCart', body.cart)
				}
      } else {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          context.commit('doLogout');
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("LoginIncorrect");
        }
      }
    },
		// register
    async register(context, user) {
      let logindata = new FormData();
      logindata.append('SignupForm[email]', user.email)
      logindata.append('SignupForm[password]', user.password)
      logindata.append('SignupForm[passwordRepeat]', user.password)
      logindata.append('token', user.token)
      logindata.append('lang', user.lang)
      logindata.append('clientID', context.state.user.clientID)

      const requestOptions = {
        method: 'POST',
        body: logindata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/register', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log('body:' , body)
        if (body.user) {
          console.log(body.user)
          Vue.prototype.$cookies.set('user_token', body.user.auth_key, 60 * 60 * 24 * 1);
          localStorage.setItem('rememberMe', false);
          context.commit('loginSuccess', body.user);
        }
      } else {
        if (response.status === 401) {
          // auto logout if 401 response returned from api
          context.commit('doLogout');
        }
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("RegisterIncorrect");
        }
      }
    },
		// new password request
    async newPasswordRequest(context, user) {
      let logindata = new FormData();
      console.log(user)
      logindata.append('PasswordResetRequestForm[email]', user.email)
      logindata.append('token', user.token)
      const requestOptions = {
        method: 'POST',
        body: logindata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/request-new-password', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.success) {
          console.log('Запрос на смену пароля успешно отправлен')
        }
      } else {
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("RegisterIncorrect");
        }
      }
    },
		// validate password reset token
    async validateResetToken(context, {resetToken}) {
      console.log(resetToken)
      const requestOptions = {
        method: 'POST',
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/validate-reset-token?token=' + resetToken, requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа
        const body = await response.json()
        if (body.success) {
          console.log('Токен верный')
          return 1
        } else {
          return -1
        }
      } else {
          return -1
      }
    },
		// new password reset
    async newPasswordReset(context, {password, resetToken, token}) {
      let logindata = new FormData();
      logindata.append('ResetPasswordForm[password]', password)
      logindata.append('reset_token', resetToken)
      logindata.append('token', token)
      const requestOptions = {
        method: 'POST',
        body: logindata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/reset-new-password', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.success) {
          console.log('Пароль успешно сменен')
        }
      } else {
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("PasswordResetFailed");
        }
      }
    },
		// consultation request
    async consult(context, user) {
      let logindata = new FormData();
      console.log(user)
      logindata.append('name', user.name)
      logindata.append('email', user.email)
      logindata.append('phone', user.phone)
      logindata.append('company', user.company)
      logindata.append('token', user.token)
      const requestOptions = {
        method: 'POST',
        body: logindata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/consult', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log(body)
        if (body.success) {
          console.log('Сообщение успешно отправлено')
        }
      } else {
        const body = await response.json()
        console.log(body)
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ConsultIncorrect");
        }
      }
    },
		// profile edit
    async profileEdit(context, user) {
      let userdata = new FormData();
      console.log(user)
      userdata.append('id', user.id)
      userdata.append('auth_key', Vue.prototype.$cookies.get('user_token'))
      userdata.append('first_name', user.firstName)
      userdata.append('email', user.email)
      userdata.append('phone', user.phone)
      userdata.append('telegram', user.telegram)
      userdata.append('country', user.country.value)
      userdata.append('region', user.region)
      userdata.append('city', user.city)
      userdata.append('address', user.address)
      userdata.append('act_type', user.actType)
      userdata.append('contact_name', user.contactName)
      userdata.append('contact_phone', user.contactPhone)
      userdata.append('contact_index', user.contactIndex)
      userdata.append('token', user.token)
      const requestOptions = {
        method: 'POST',
        body: userdata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/edit-profile', requestOptions)
      console.log(response)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log(body)
        if (body.success) {
          console.log('Профиль отредактирован')
        }
        if (body.user) {
          context.commit('updateUser', body.user);
          console.log('Профиль отредактирован')
        }
      } else {
        const body = await response.json()
        console.log(body)
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ProfileEditIncorrect");
        }
      }
    },
    async makeAutoLogin(context) {
      let token = Vue.prototype.$cookies.get('user_token')
      let rememberMe = localStorage.getItem('rememberMe')
      if (token && rememberMe) {
        console.log('auth_key: ', token)
        let logindata = new FormData();
        logindata.append('auth_key', token)
        const requestOptions = {
          method: 'POST',
          body: logindata
        };
        const response = await fetch('//' + VUE_APP_BACK_URL + '/api/login-by-token', requestOptions)
        console.log(response)
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            
          // получаем тело ответа (см. про этот метод ниже)
          const body = await response.json()
          if (body.user) {
            Vue.prototype.$cookies.remove('user_token');
            Vue.prototype.$cookies.set('user_token', body.user.auth_key, 60 * 60 * 24 * 1);
            context.commit('loginSuccess', body.user);

            if (body.user.clientID && Vue.prototype.$metrika) {
              Vue.prototype.$metrika.setUserID(body.user.clientID)
            }

          }
          if (body.cart) {
            context.commit('updateCart', body.cart)
          }
        } else {
          if (response.status === 401) {
            // auto logout if 401 response returned from api
            context.commit('doLogout')
          }
          const body = await response.json()
          console.log(body)
          router.push('/login')
        }
      } else {
        context.commit('doLogout');
      }
    },
    async setClientID(context, { clientID }) {

      if (context.state.isLogged && context.state.user.clientID !== clientID) {
        const token = Vue.prototype.$cookies.get('user_token')
        let logindata = new FormData();
        logindata.append('auth_key', token)
        logindata.append('clientID', clientID)
        const requestOptions = {
          method: 'POST',
          body: logindata
        };

        const response = await fetch('//' + VUE_APP_BACK_URL + '/api/set-client-id', requestOptions)
        console.log('set-clientid response', response)
        if (response.ok) { // если HTTP-статус в диапазоне 200-299
            
          // получаем тело ответа (см. про этот метод ниже)
          const body = await response.json()
          console.log('set-clientid body', body)
        } else {
          if (response.status === 401) {
            // auto logout if 401 response returned from api
            context.commit('doLogout')
          }
          const body = await response.json()
          console.log('set-clientid error body', body)
        }
      }

      context.commit('doSetClientID', { clientID })
    },
    incrementBoughts(context) {
      context.commit('doIncrementBoughts') // после оплаты покупки нужно увеличить
    },
    makeLogout(context) {
      context.commit('doLogout')
      context.commit('doClearCart')
    },
    sandwichOpen(context) {
      context.commit('doSandwichOpen')
    },
    contactsPopupOpen(context) {
      context.commit('doContactsPopupOpen')
    },
    contactsPopupClose(context) {
      context.commit('doContactsPopupClose')
    },
    sandwichClose(context) {
      context.commit('doSandwichClose')
    },
    sandwichToggle(context) {
      context.commit('doSandwichToggle')
    },
    phoneCodeChange(context, {phoneField, code}) {
      context.commit('doPhoneCodeChange', {phoneField, code})
    },
	},
	mutations: {
		settingsLoaded(state, loaded) {
			if (!loaded) {
				state.lang = []
				state.menu = []
				state.languages = []
				state.translations = []
				state.settings = []
			}
			state.settingsLoaded = loaded
		},
		updateLang(state, lang) {
			state.lang = lang
		},
		updateTopMenu(state, menu) {
			state.topMenu = menu
		},
		updateBottomMenu(state, menu) {
			state.bottomMenu = menu
		},
		updateSettings(state, settings) {
			state.settings = settings
		},
		updateTranslations(state, translations) {
			state.translations = translations
		},
		updateLanguages(state, languages) {
			state.languages = languages
		},
		updateSocial(state, social) {
			state.social = social
		},
	
		loginSuccess(state, user) {
      state.isLogged = true
      state.user = user
      state.token = user.token
    },
		updateUser(state, user) {
      state.user = user
      if (state.token !== user.auth_key) {
        Vue.prototype.$cookies.set('user_token', user.auth_key, 60 * 60 * 24 * 1);
        state.token = user.auth_key
      }
    },
    doSetClientID(state, { clientID }) {
      state.user.clientID = clientID
    },
    updateUserYurDef(state, { yurName, yurInn}) {
      state.user.def_your_name = yurName
      state.user.def_your_inn = yurInn
    },
    doLogout(state) {
      Vue.prototype.$cookies.remove('user_token')
      localStorage.removeItem('manager_token');
      localStorage.removeItem('manager_user_id');
      state.isLogged = false
      state.user = {
        clientID: state.user.clientID,
        phoneMask: { iso_code_2: "RU", mask: defPhoneMask },
        contact_phoneMask: { iso_code_2: "RU", mask: defPhoneMask },
        boughts: 0,
      }
      state.token = ''
    },
    doIncrementBoughts(state) {
      state.user.boughts += 1
    },
    doSandwichOpen(state) {
      state.sandwichOpened = true;
    },
    doSandwichClose(state) {
      state.sandwichOpened = false;
    },
    doContactsPopupOpen(state) {
      state.contactsPopupOpened = true;
    },
    doContactsPopupClose(state) {
      state.contactsPopupOpened = false;
    },
    doSandwichToggle(state) {
      state.sandwichOpened = !state.sandwichOpened;
    },
    doPhoneCodeChange(state, subject) {
      const mask = state.settings.phoneMasks[subject.code];
      if (subject.phoneField === "phone") {
        state.user.phoneMask = {
          iso_code_2: subject.code,
          mask,
        }
      }
      if (subject.phoneField === "contact-phone") {
        state.user.contact_phoneMask = {
          iso_code_2: subject.code,
          mask,
        }
      }
    }
	},
	state() {
    return {
      lang: [],
      topMenu: [],
      bottomMenu: [],
      languages: [],
      translations: [],
      jurisdictions: [],
      settings: [],
      social: [],
      settingsLoaded: false,

      sandwichOpened: false,
      contactsPopupOpened: false,
    
      isLogged: false,
      user: {
        clientID: null,
        phoneMask: { iso_code_2: "RU", mask: defPhoneMask },
        contact_phoneMask: { iso_code_2: "RU", mask: defPhoneMask },
        boughts: 0,
      },
      token: '',
    }
  },
	getters: {
		isSettingsLoaded: state => {
			return state.settingsLoaded
		},
		listLogo: state => {
			return '//' + VUE_APP_BACK_URL + state.settings.logo
		},
		listPhone: state => {
			return {
				phone: state.settings.phone,
				phone_link: 'tel:' + state.settings.phone_link,
			}
		},
		listTopMenu: state => {
			return state.topMenu
		},
		listBottomMenu: state => {
			return state.bottomMenu
		},
		listSettings: state => {
			return state.settings
		},
		listLanguages: state => {
			return state.languages
		},
		listLang: state => {
			return state.lang
		},
		listSocial: state => {
			return state.social
		},
		listContacts: state => {
			return {
        phone: state.settings.phone,
        phone2: state.settings.phone2,
				phone_link: 'tel:' + state.settings.phone_link,
        email: state.settings.email,
        address: state.settings.address,
      }
		},
		translations: (state) => (phrase, params = []) => {
			// console.log(params)
			if (state.translations[phrase] && params.length) {
				let retVal = state.translations[phrase]
				params.forEach(function(item) {
					retVal = retVal.replace('%s', item)
				});
				return retVal
			} else {
				return state.translations[phrase] ? state.translations[phrase] : phrase
			}
		},
		listAppLanguage: state => {
			return state.settings.appLanguage
		},

    isUserLogged: state => {
      return state.isLogged
    },
    ListLoggedUser: state => {
      return state.user
    },
    isSandwichOpened: state => {
      return state.sandwichOpened
    },
    isContactsPopupOpened: state => {
      return state.contactsPopupOpened
    },
    listCountryMask: (state) => (code) => {
      return state.settings.phoneMasks[code];
    },

	},
}