<template>
  <FormWrapper
    :formTitle="translations('GetFreeConsultNow')"
    :v="$v.form"
    :formButton="translations('Submit')"
    :successMessage="translations('YouMessageSubmitted')"
    :formCallback="doConsult"
    @form-success="goFurther"
  >
    <TextField
      v-model="form.name"
      :v="$v.form.name"
      :fieldLabel="translations('EnterYourName')"
    />
    <EmailField
      v-model="form.email"
      :v="$v.form.email"
      :fieldLabel="translations('EnterYourEmail')"
    />
    <PhoneField
      v-model="form.phone"
      :v="$v.form.phone"
      :fieldLabel="translations('EnterYourPhone')"
      :phoneMask="form.phoneMask || getPhoneMask"
      :countryCode="form.phoneCode || 'RU'"
      @code-change="codeChange"
    />
    <TextField
      v-model="form.company"
      :v="$v.form.company"
      :fieldLabel="translations('YourCompanyInfo')"
    />
  </FormWrapper>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import FormWrapper from "./FormWrapper";
import PhoneField from "./FieldComponents/PhoneField";
import EmailField from "./FieldComponents/EmailField";
import TextField from "./FieldComponents/TextField";
import { helpers } from "vuelidate/lib/validators";
import { email, required, minLength } from "vuelidate/lib/validators";

const phoneMask = "7 (###) ###-##-##";
const fullphone = (value, vm) => {
  return value.length === vm.phoneMask.length || !helpers.req(value);
};
const emailOrPhoneReq = (value, vm) => {
  return (
    String(vm.phone).trim().length > 0 || String(vm.email).trim().length > 0
  );
};

export default {
  name: "ContactForm",
  data: () => {
    return {
      form: {
        name: "",
        email: "",
        phone: "",
        phoneCode: "RU",
        phoneMask: "",
        company: "",
      },
    };
  },
  components: { FormWrapper, EmailField, PhoneField, TextField },
  computed: {
    ...mapGetters([
      "translations",
      "isUserLogged",
      "ListLoggedUser",
      "ListSettings",
      "listCountryMask",
    ]),
    getPhoneMask() {
      return phoneMask;
    },
  },
  watch: {
    "form.name"(v) {
      //      this.$nextTick(() => {
      this.form.name = String(v).replace(/\d/g, "");
      //      });
    },
  },
  validations: {
    form: {
      name: { minLength: minLength(3) },
      email: { email, emailOrPhoneReq },
      phone: { emailOrPhoneReq, fullphone },
      company: { required },
    },
  },
  methods: {
    ...mapActions(["consult"]),
    async doConsult(recaptchaToken) {
      console.log("начало отправки запроса консультации");
      await this.consult({
        name: this.form.name,
        email: this.form.email,
        phone: this.form.phone,
        company: this.form.company,
        token: recaptchaToken,
      });
    },
    async codeChange(value) {
      this.form.phoneCode = value;
      console.log("value: ", value);
      this.form.phoneMask = await this.listCountryMask(value);
    },
    goFurther() {
      // дальнейшие действия после успешной отправки формы
    },
  },
  mounted() {
    if (this.isUserLogged) {
      this.form.name =
        this.ListLoggedUser.first_name + " " + this.ListLoggedUser.last_name;
      this.form.phone = this.ListLoggedUser.phone;
      this.form.email = this.ListLoggedUser.email;
    }
    this.form.phoneMask = phoneMask;
  },
};
</script>