import Vue from 'vue'
import Vuelidate from 'vuelidate'
import VueCookies from 'vue-cookies'
import VueMeta from 'vue-meta'
import App from './App.vue'
import router from './router'
import store from './store'
import dateFilter from '@/filters/date.filter'
import YmapPlugin from 'vue-yandex-maps'
import Multiselect from 'vue-multiselect'
import Paginate from 'vuejs-paginate'
import Breadcrumbs from '@/components/app/Breadcrumbs'
import VTooltip from 'v-tooltip'
import VueSweetalert2 from 'vue-sweetalert2'
import VueScrollTo from 'vue-scrollto'
import VueYandexMetrika from 'vue-yandex-metrika'
import dotenv from 'dotenv';
import { mapSettings, metrikaCode } from "@/config";

import 'sweetalert2/dist/sweetalert2.min.css';

Vue.config.productionTip = false

Vue.component('multiselect', Multiselect)
Vue.component('paginate', Paginate)
Vue.component('Breadcrumbs', Breadcrumbs)

Vue.filter('date', dateFilter)

Vue.use(Vuelidate)
Vue.use(VTooltip)
Vue.use(VueSweetalert2);
Vue.use(VueScrollTo, {
     container: "body",
     duration: 500,
     easing: "ease",
     offset: 0,
     force: true,
     cancelable: true,
     onStart: false,
     onDone: false,
     onCancel: false,
     x: false,
     y: true
})
 
Vue.use(VueCookies)
Vue.$cookies.config(60 * 60 * 24 * 30, '');
if (!Vue.$cookies.isKey('user_language')) {
  Vue.$cookies.set('user_language', 'ru-RU')
}

Vue.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
})

Vue.use(YmapPlugin, mapSettings)

let token = ''

if (Vue.$cookies.isKey('user_token')) {
  token = Vue.$cookies.get('user_token')
}

dotenv.config();

window.dataLayer = window.dataLayer || [];

document.addEventListener("yacounter" + metrikaCode + "inited", () => {
  console.log("metrika inited in main.js! Setting ClientID...");
  Vue.prototype.$metrika.getClientID(function(clientID) {
    console.log("ClientID:", clientID);
    store.dispatch('setClientID', { clientID })
  })
  return true;
});

Vue.use(VueYandexMetrika, {
  id: metrikaCode,
  router: router,
//  env: process.env.NODE_ENV !== 'development' ? 'production' : 'development',
  env: 'production',
  debug: true,
  options: {
    webvisor: true,
    ecommerce: 'dataLayer',
    triggerEvent: true,
  },
})

if (token) {

  store.dispatch('makeAutoLogin').then(() => {
    new Vue({
      router,
      store,
      render: h => h(App)
    }).$mount('#app')    
  })

} else {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')    
}