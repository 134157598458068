const routes = [
  {
    path: '/',
    name: 'Home',
    meta: {
      key: 0,
      layout: 'main-layout',
      headerDark: true,
      transition: 'fade',
    },
    component: () => import('../views/Home.vue')
  },
  {
    path: '/about',
    name: 'About',
    meta: {
      key: 1,
      layout: 'main-layout',
      auth: false,
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'AboutUs',
    },
    component: () => import('../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    meta: {
      key: 2,
      layout: 'login-layout',
      notAuth: true,
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/user/Login.vue')
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    meta: {
      key: 3,
      layout: 'login-layout',
      notAuth: true,
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/user/ForgotPassword.vue')
  },
  {
    path: '/reset-password',
    name: 'ResetPassword',
    meta: {
      key: 3,
      layout: 'login-layout',
      notAuth: true,
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/user/ResetPassword.vue')
  },
  {
    path: '/account',
    redirect: '/account/profile',
    name: 'Account',
    meta: {
      key: 4,
      layout: 'account-layout',
      auth: true,
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'Account',
    },
    component: () => import('../views/user/Account.vue'),
    children: [
      {
        path: 'profile',
        name: 'Profile',
        meta: {
          key: 4,
          subkey: 1,
          layout: 'account-layout',
          auth: true,
          headerDark: false,
          subtransition: 'fade',
          breadcrumb: 'Profile',
        },
        component: () => import('../views/user/UserProfile.vue')
      },
      {
        path: 'products',
        name: 'Products', 
        meta: {
          key: 4,
          subkey: 2,
          layout: 'account-layout',
          auth: true,
          headerDark: false,
          transition: 'fade',
          subtransition: 'fade',
          breadcrumb: 'Orders',
        },
        component: () => import('../views/user/UserProducts.vue')
      },
      {
        path: 'documents',
        name: 'Documents',
        meta: {
          key: 4,
          subkey: 3,
          layout: 'account-layout',
          auth: true,
          headerDark: false,
          transition: 'fade',
          subtransition: 'fade',
          breadcrumb: 'Documents',
        },
        component: () => import('../views/user/UserDocuments.vue')
      },
      {
        path: 'yurlitsa',
        name: 'YurLitsa',
        meta: {
          key: 4,
          subkey: 4,
          layout: 'account-layout',
          auth: true,
          headerDark: false,
          subtransition: 'fade',
          breadcrumb: 'YurLitsa',
        },
        component: () => import('../views/user/UserYurlitsa.vue')
      },
    ]
  },
  {
    path: '/page/:id',
    name: 'Pages',
    meta: {
      key: 5,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/Page.vue'),
  },
  {
    path: '/country/:id',
    name: 'Country',
    meta: {
      key: 6,
      layout: 'main-layout',
      headerDark: true,
      country: true,
      transition: 'fade',
      breadcrumb: 'Country',
    },
    component: () => import('../views/Country.vue'),
  },
  {
    path: "/faq",
    name: 'Faqs',
    meta: {
      key: 7,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'Faq',
    },
    component: () => import('../views/Faq.vue')
  },
  {
    path: "/foreign-registers",
    name: 'ForeignRegisters',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'ForeignRegisters',
    },
    component: () => import('../views/ForeignRegisters.vue')
  },
  {
    path: "/tariffs",
    name: 'Tariffs',
    meta: {
      key: 9,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'Tariffs',
    },
    component: () => import('../views/Tariffs.vue'),
  },
  {
    path: "/tariffs/:id",
    name: 'CountryTariffs',
    meta: {
      key: 10,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/CountryTariffs.vue'),
  },
  {
    path: "/materials",
    redirect: '/materials/news',
    name: 'Materials',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'Materials',
    },
    component: () => import('../views/Materials.vue'),
    children: [
      {
        path: "news",
        name: 'News',
        meta: {
          key: 8,
          layout: 'main-layout',
          headerDark: false,
          transition: 'fade',
          breadcrumb: 'News',
        },
        component: () => import('../views/News.vue'),
      },
      {
        path: 'news/:id',
        name: 'NewsArticle',
        meta: {
          key: 6,
          layout: 'main-layout',
          article: 100,
          headerDark: false,
          transition: 'fade',
        },
        component: () => import('../views/Article.vue'),
      },
      {
        path: "publications",
        name: 'Publications',
        meta: {
          key: 8,
          layout: 'main-layout',
          headerDark: false,
          transition: 'fade',
          breadcrumb: 'Publications',
        },
        component: () => import('../views/Publications.vue'),
      },
      {
        path: 'publications/:id',
        name: 'PublicationsArticle',
        meta: {
          key: 6,
          layout: 'main-layout',
          article: 1,
          headerDark: false,
          transition: 'fade',
        },
        component: () => import('../views/Article.vue'),
      },
    ]
  },
  {
    path: "/search",
    name: 'Search',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/Search.vue')
  },
  {
    path: "/search2",
    name: 'Search2',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
    },
    component: () => import('../views/Search2.vue')
  },
  {
    path: "/search/select-type",
    name: 'SelectProductType',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'ProductTypeSelection',
    },
    component: () => import('../views/SelectProductType.vue')
  },
  {
    path: "/search/select-product",
    name: 'SelectProduct',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'ProductSelection',
    },
    component: () => import('../views/SelectProduct.vue')
  },
  {
    path: "/checkout",
    name: 'Checkout',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'CheckoutPage',
    },
    component: () => import('../views/Checkout.vue')
  },
  {
    path: "/order-success",
    name: 'OrderSuccess',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'OrderSuccessfullyCreated',
    },
    component: () => import('../views/OrderSuccess.vue')
  },
  {
    path: "/payment-success",
    name: 'PaymentSuccess',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'PaymentSuccess',
    },
    component: () => import('../views/PaymentSuccess.vue')
  },
  {
    path: "/payment-fail",
    name: 'PaymentFail',
    meta: {
      key: 8,
      layout: 'main-layout',
      headerDark: false,
      transition: 'fade',
      breadcrumb: 'PaymentFail',
    },
    component: () => import('../views/PaymentFail.vue')
  },
  {
    path: "/404",
    alias: '*',
    name: 'PageNotFound',
    meta: {
      key: 404,
      layout: 'not-found-layout',
      headerDark: true,
      transition: 'fade',
      breadcrumb: '404',
    },
    component: () => import('../views/PageNotFound.vue')
  }
]

export default routes