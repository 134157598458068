import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import routes from './routes'
import expiringStorage from "@/expiringStorage";
import { managerExpire } from '@/config';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: true,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
 })

router.beforeEach(async (to, from, next) => {
  const settingsLoaded = store.getters.isSettingsLoaded
  const requireAuth = to.matched.some(record => record.meta.auth)
  const requireOut = to.matched.some(record => record.meta.notAuth)
  const userLogged = store.getters.isUserLogged

  const is_manager = expiringStorage.get('manager_token');
  const manager_has_user = expiringStorage.get('manager_user_id');
  const manager_token = to.query.manager_token || '';
  const user_id = to.query.user_id || 0;

  await store.dispatch('sandwichClose')

  if ((!is_manager && !manager_has_user) || is_manager !== manager_token ||
    manager_has_user !== user_id) {
    
    if (manager_token && user_id) {
      expiringStorage.set('manager_token', manager_token, managerExpire);
      expiringStorage.set('manager_user_id', user_id, managerExpire);
    }
  }

  if (requireAuth && !userLogged) {
    if (!settingsLoaded) {
      await store.dispatch('fetchSettings')
    } 
    if (!userLogged) {
      next('/login?message=auth_required')
    } else {
      next('/account')
    }
  } else if (userLogged && requireOut) {
    next('/account')
  } else {
    next()
  }
})

export default router