import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchTypePurposes(context, {lang, curCountry}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('country_id', curCountry)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/type-purposes', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log('load all data')
        if (body.typePurposes) {
          //console.log(body.typePurposes)
          context.commit('updateTypePurposes', body.typePurposes)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    setCurTypePurpose(context, curTypePurpose) {
      context.commit('updateCurTypePurpose', curTypePurpose)
    },
		refreshTypePurposes(context) {
			context.commit('typePurposesLoaded', false)
		}
},
  mutations: {
    updateTypePurposes(state, typePurposes) {
      state.typePurposes = typePurposes
      state.typePurposesLoaded = true
      state.curTypePurpose = []
    },
    updateCurTypePurpose(state, curTypePurpose) {
      state.curTypePurpose = state.typePurposes.find((tp) => tp.id === curTypePurpose)
    },
		typePurposesLoaded(state, loaded) {
			if (!loaded) {
				state.typePurposes = []
				state.curTypePurpose = []
			}
			state.typePurposesLoaded = loaded
		},
  },
  state: {
    typePurposesLoaded: false,
    typePurposes: [],
    curTypePurpose: [],
  },
  getters: {
    typePurposesLoaded(state) {
      return state.typePurposesLoaded
    },
    curTypePurpose(state) {
      return state.curTypePurpose
    },
    listTypePurposes(state) {
      return state.typePurposes
    },
    listPurposeProductTypes(state) {
      return state.typePurposes.find((tp) => tp.id === +state.curTypePurpose.id).productTypes
    },
  }
}