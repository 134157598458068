import Vue from 'vue'

import dotenv from 'dotenv'
dotenv.config()

let BACK_URL = 'new.egrul.ru'
if (process.env.NODE_ENV === 'development') {
	BACK_URL = 'ergul.site'
}
export const VUE_APP_BACK_URL = BACK_URL
//export const VUE_APP_BACK_URL="egrul.thekan.ru"
//export const VUE_APP_BACK_URL="ergul.site"
export const mapSettings = {
	apiKey: '2315e588-5f6f-41c2-a457-f10e0739712a',
	lang: 'ru_RU',
	coordorder: 'latlong',
	version: '2.1',
}
export const metrikaCode = 86462474
export const countryExpire = 100
export const managerExpire = 20
export function pluralType(n, lang) {
	if (lang === 'ru-RU') {
		return n % 10 == 1 && n % 100 != 11
			? 0
			: n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)
			? 1
			: 2
	} else {
		return n === 1 ? 0 : 1
	}
}
export function pluralName(n, lang) {
	if (lang === 'ru-RU') {
		return n % 10 == 1 && n % 100 != 11 ? 0 : 1
	} else {
		return 0
	}
}
export function numberFormat(number, decimals, dec_point, thousands_sep) {
	// Strip all characters but numerical ones.
	number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
	var n = !isFinite(+number) ? 0 : +number,
		prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
		sep = typeof thousands_sep === 'undefined' ? ',' : thousands_sep,
		dec = typeof dec_point === 'undefined' ? '.' : dec_point,
		s = '',
		toFixedFix = function(n, prec) {
			var k = Math.pow(10, prec)
			return '' + Math.round(n * k) / k
		}
	// Fix for IE parseFloat(0.55).toFixed(0) = 0;
	s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.')
	if (s[0].length > 3) {
		s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
	}
	if ((s[1] || '').length < prec) {
		s[1] = s[1] || ''
		s[1] += new Array(prec - s[1].length + 1).join('0')
	}
	return s.join(dec)
}

export function strippedContent(string) {
	return string.replace(/<\/?[^>]+>/gi, '')
}
export function toastAlert(title, type = 'info') {
	Vue.swal({
		toast: true,
		position: 'top-right',
		iconColor: 'white',
		customClass: {
			popup: 'colored-toast',
		},
		showConfirmButton: false,
		timer: 1500,
		timerProgressBar: true,
		icon: type,
		title: title,
	})
}
export const validationDelay = 500
export const defPhoneMask = '7 (###) ###-##-##'
export function getValidationErrorMessage(validator) {
	for (var param in validator.$params) {
		let paramData = validator.$params[param]
		if (!validator[param]) {
			if (param === 'required') {
				return { message: 'FieldCannotBeEmpty' }
			}
			if (param === 'email') {
				return { message: 'FieldMustBeEmail' }
			}
			if (param === 'minLength') {
				return {
					message: 'FieldMustBeMinLength',
					params: paramData.min ? [paramData.min] : [],
				}
			}
			if (param === 'maxLength') {
				return {
					message: 'FieldMustBeMaxLength',
					params: paramData.max ? [paramData.max] : [],
				}
			}
			if (param === 'minValue') {
				return {
					message: 'FieldMustBeMinValue',
					params: paramData.min ? [paramData.min] : [],
				}
			}
			if (param === 'maxValue') {
				return {
					message: 'FieldMustBeMaxValue',
					params: paramData.max ? [paramData.max] : [],
				}
			}
			if (param === 'between') {
				return {
					message: 'FieldMustBeBetween',
					params: paramData.min ? [paramData.min, paramData.max] : [],
				}
			}
			if (param === 'sameAs') {
				return { message: 'FieldsMustBeEqual' }
			}
			if (param === 'sameAsPassword') {
				return { message: 'PasswordShouldBeTheSame' }
			}
			if (param === 'fullphone') {
				return { message: 'PhoneMustBeFull' }
			}
			if (param === 'alphaOnly') {
				return { message: 'NoNumbersAllowed' }
			}
			if (param === 'emailOrPhoneReq') {
				return { message: 'EmailOrPhoneRequired' }
			}
			return { message: 'UnknownError' }
		}
	}
}
