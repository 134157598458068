export default function dateFilter(value, format = 'date', lang = 'ru-RU') {
  const options = {}

  if (format === 'year') {
    options.year = 'numeric'
  } else {
    if (format.includes('date')) {
      options.day = '2-digit'
      options.month = '2-digit'
      options.year = 'numeric'
    }

    if (format.includes('time')) {
      options.hour = '2-digit'
      options.minute = '2-digit'
      options.second = '2-digit'
    }
  }
  return new Intl.DateTimeFormat(lang, options).format(new Date(value))
}