<template>
  <div class="footer-nav">
    <router-link v-for="item in listBottomMenu" :key="item.url" :to="item.url">
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "bottom-menu",
  components: {},
  computed: {
    ...mapGetters(["listBottomMenu"]),
  },
};
</script>