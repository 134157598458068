import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchCompanies(context, {lang, query, countryId, countryCode, page = 1}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('query', query)
      bodydata.append('country_id', countryId)
      bodydata.append('code', countryCode)
      bodydata.append('page', page)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/companies', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log('load all data', body)
        if (body.companies) {
          context.commit('updateCompanies', {body, page})
        }
        if (body.products) {
          //console.log(body.companies)
          context.commit('updateProducts', body.products)
        }
        if (body.typePurposes) {
          //console.log(body.companies)
          context.commit('updateTypePurposes', body.typePurposes)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    async fetchCompanies2(context, {lang, query, countryId, countryCode, page = 1}) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('query', query)
      bodydata.append('country_id', countryId)
      bodydata.append('code', countryCode)
      bodydata.append('page', page)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/companies2?page=' + page, requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        console.log('load all data', body)
        if (body.companies) {
          context.commit('updateCompanies2', body)
        }
        if (body.products) {
          //console.log(body.companies)
          context.commit('updateProducts', body.products)
        }
        if (body.typePurposes) {
          //console.log(body.companies)
          context.commit('updateTypePurposes', body.typePurposes)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
    toggleCompanyOpen(context, companyId) {
      context.commit('updateCompanyOpen', companyId)
    },
    setCurCompany(context, curCompany) {
      context.commit('updateCurCompany', curCompany)
    },
    setCompanyFromQuery(context, { query, country }) {
      context.commit('addQueryCompany', { query, country })
    },
    async fetchCommercialOffer(context, { lang, company, countryId }) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      bodydata.append('country_id', countryId)
      bodydata.append('company_name', company.name)
      bodydata.append('company_country', company.country)
      bodydata.append('company_inn', company.inn)
      bodydata.append('company_date', company.updated_at)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/commercial-offer', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
          
        // получаем тело ответа (см. про этот метод ниже)
        const blob = await response.blob();

        const blobUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = 'offer.pdf';

        document.body.appendChild(link);

        link.click();

        setTimeout(() => {
          link.remove();
          // For Firefox it is necessary to delay revoking the ObjectURL.
          // https://bugzilla.mozilla.org/show_bug.cgi?id=1282407
          window.URL.revokeObjectURL(blobUrl);
        }, 100);
      } else {
        const body = await response.json()
        if (body.error) {
          throw new Error(body.error);
        } else {
          throw new Error("ErrorFetchingPriceList");
        }
      }
    },
		clearCompanies(context) {
			context.commit('setCompaniesClear')
		},
},
  mutations: {
    async updateCompanies(state, {body, page}) {
      if (page === 1) {
        state.companies = body.companies
        state.companiesCount = body.companies_total
        state.companiesPages = body.companies_pages
        state.companiesError = body.companies_error
        state.companiesLoaded = true
        state.curCompany = []
      } else {
        await state.companies.push(...body.companies)
      }
    },
    updateCompanies2(state, body) {
      state.companies = body.companies
      state.companiesCount = body.companies_total
      state.companiesLoaded = true
      state.curCompany = []
    },
    setCompaniesClear(state) {
      state.companiesLoaded = false
      state.companies = []
      state.curCompany = []
    },
    addQueryCompany(state, { query, country }) {

      let newID = localStorage.getItem('newCompanyId');
      if (!newID) {
        newID = 1000000000
      } else {
        newID = parseInt(newID)
      }
      localStorage.setItem('newCompanyId', (newID + 1));

      state.companies = [{
        id: newID,
        companyId: 1,
        name: query,
        country: country,
        inn: '-',
        status: '-',
        type: '-',
        address: '-',
        productsExpanded: false,
      }]
      state.curCompany = state.companies[0]
      state.companiesLoaded = true
      state.companiesCount = 1
    },
    updateCompanyOpen(state, companyId) {
      let found = state.companies.find((company) => company.id === companyId)
      found.productsExpanded = !found.productsExpanded
    },
    updateCurCompany(state, curCompany) {
      state.curCompany = state.companies.find((company) => company.id === curCompany)
    },
  },
  state: {
    companiesLoaded: false,
    companies: [],
    companiesCount: 0,
    companiesPages: 0,
    companiesError: false,
    curCompany: [],
    curPage: 1,
  },
  getters: {
    companiesLoaded(state) {
      return state.companiesLoaded
    },
    isCompanyOpen: (state) => companyId => {
      let found = state.companies.find((company) => company.id === companyId)
      return found.productsExpanded
    },
    curCompany(state) {
      return state.curCompany
    },
    curCompaniesPage(state) {
      return state.curPage
    },
    listCompanies(state) {
      return state.companies
    },
    listCompaniesCount(state) {
      return state.companiesCount
    },
    listCompaniesError(state) {
      return state.companiesError
    },
    listCompany: (state) => companyId => {
      return state.companies.find((company) => company.id === companyId)
    },
  }
}