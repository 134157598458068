import { VUE_APP_BACK_URL } from '@/config';
import 'whatwg-fetch'

export default {
  actions: {
    async fetchFirstAdvantages(context, lang) {
      let bodydata = new FormData();
      bodydata.append('lang', lang)
      const requestOptions = {
        method: 'POST',
        body: bodydata
      };
      const response = await fetch('//' + VUE_APP_BACK_URL + '/api/first-advantages', requestOptions)
      if (response.ok) { // если HTTP-статус в диапазоне 200-299
        // получаем тело ответа (см. про этот метод ниже)
        const body = await response.json()
        if (body.records) {
          //console.log(body.typePurposes)
          context.commit('updateAdvantages', body.records)
        }
      } else {
        alert("Ошибка HTTP: " + response.status);
      }
    },
		refreshFirstAdvantages(context) {
			context.commit('advantagesLoaded', false)
		}
  },
  mutations: {
    updateAdvantages(state, advantages) {
      state.advantages = advantages
      state.advantagesLoaded = true
    },
		advantagesLoaded(state, loaded) {
			if (!loaded) {
				state.advantages = []
			}
			state.advantagesLoaded = loaded
		},
  },
  state: {
    advantagesLoaded: false,
    advantages: [],
  },
  getters: {
    advantagesLoaded(state) {
      return state.advantagesLoaded
    },
    listAdvantages(state) {
      return state.advantages
    },
  }
}