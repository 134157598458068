<template>
  <transition name="modal" appear>
    <div class="modal-mask">
      <div class="modal-wrapper" @click="handleClick($event)">
        <div
          class="modal-container"
          :class="[
            {
              'full-width': contentType === 'video' || contentType === 'image',
            },
            { video: contentType === 'video' },
            { picture: contentType === 'image' },
          ]"
          :style="'width: ' + width + '; height: ' + height"
        >
          <svg
            :class="[
              'close',
              { outside: contentType === 'video' || contentType === 'image' },
            ]"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            @click="$emit('close')"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z"
              fill="#2D2E3C"
            />
          </svg>
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Popupper",
  props: {
    width: {
      type: String,
      default: "500px",
    },
    height: {
      type: String,
      default: "auto",
    },
    contentType: {
      type: String,
      default: "usual",
    },
  },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    handleClick(event) {
      if (event.target.classList.contains("modal-wrapper")) {
        this.$emit("close");
      }
    },
    escPressed(e) {
      if (e.keyCode === 27) {
        this.$emit("close");
      }
    },
  },
  mounted() {
    document.addEventListener("keyup", this.escPressed);
    document.querySelector("body").classList.add("noscroll");
  },
  beforeDestroy() {
    document.querySelector("body").classList.remove("noscroll");
    document.removeEventListener("keyup", this.escPressed);
  },
};
</script>
