var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('transition',{attrs:{"name":"modal","appear":""}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper",on:{"click":function($event){return _vm.handleClick($event)}}},[_c('div',{staticClass:"modal-container",class:[
          {
            'full-width': _vm.contentType === 'video' || _vm.contentType === 'image',
          },
          { video: _vm.contentType === 'video' },
          { picture: _vm.contentType === 'image' } ],style:('width: ' + _vm.width + '; height: ' + _vm.height)},[_c('svg',{class:[
            'close',
            { outside: _vm.contentType === 'video' || _vm.contentType === 'image' } ],attrs:{"width":"24","height":"24","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"},on:{"click":function($event){return _vm.$emit('close')}}},[_c('path',{attrs:{"d":"M11.9997 10.586L16.9497 5.63599L18.3637 7.04999L13.4137 12L18.3637 16.95L16.9497 18.364L11.9997 13.414L7.04974 18.364L5.63574 16.95L10.5857 12L5.63574 7.04999L7.04974 5.63599L11.9997 10.586Z","fill":"#2D2E3C"}})]),_c('div',{staticClass:"modal-header"},[_vm._t("header")],2),_c('div',{staticClass:"modal-body"},[_vm._t("body")],2),_c('div',{staticClass:"modal-footer"},[_vm._t("footer")],2)])])])])}
var staticRenderFns = []

export { render, staticRenderFns }