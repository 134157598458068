<template>
  <table>
    <tr>
      <td class="grey">{{ translations("tel") }}</td>
      <td>{{ listContacts.phone }}</td>
    </tr>
    <tr>
      <td class="grey">{{ translations("tel") }}</td>
      <td>{{ listContacts.phone2 }}</td>
    </tr>
    <tr>
      <td class="grey">{{ translations("mail") }}</td>
      <td>{{ listContacts.email }}</td>
    </tr>
    <tr>
      <td class="grey">{{ translations("address") }}</td>
      <td>{{ listContacts.address[listAppLanguage] }}</td>
    </tr>
  </table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "ContactsMenu",
  computed: {
    ...mapGetters(["listContacts", "translations", "listAppLanguage"]),
  },
};
</script>